import React from "react";
import cx from "classnames";
import NotifyStyles from "../styles/Notify.module.css";
import Center from "../styles/center.module.css";

function Notify({ duration = "5000ms", children, type = "success" }) {
  return (
    <div
      className={NotifyStyles.toastWrapper}
      style={{ "--duration": duration }}
    >
      <output className={cx(NotifyStyles.toast, Center.md, NotifyStyles[type])}>
        {children}
      </output>
    </div>
  );
}
export default Notify;

import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation, queryCache } from "react-query";

import { getEntity, updateEntity, deleteEntity } from "../api/agent";
import { invalidateAgentsIfFirstChange } from "../api/intent";
import DetailBuilder from "./DetailBuilder";
import Loading from "./Loading";
import Alert from "./Alert";
import { useNotify } from "./NotifyContext";

function DetailEdit() {
  const { agentName, entityName } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const highlight = searchParams.get("slot");
  const { status, data } = useQuery(
    ["entities", agentName, entityName],
    getEntity
  );

  const navigateBack = () => {
    if (highlight) {
      navigate({
        pathname: "..",
        search: "?" + searchParams,
      });
    } else {
      navigate("..");
    }
  };

  const [editDetail, { status: submitStatus }] = useMutation(updateEntity, {
    onSuccess: navigateBack,
  });

  const setNotification = useNotify();
  const [deleteDetail, { status: deleteStatus }] = useMutation(deleteEntity, {
    onSuccess: () => {
      //remove query for entity that was just deleted (so that no refetches occur)
      queryCache.removeQueries(["entities", agentName, entityName]);
      invalidateAgentsIfFirstChange(agentName);
      queryCache.invalidateQueries(["entities", agentName]).then(navigateBack);
      setNotification(`Successfully deleted ${data.displayName}!`);
    },
  });

  if (status === "loading") return <Loading>Loading {entityName}</Loading>;
  if (status === "error")
    return <Alert>Something went wrong loading this detail</Alert>;
  return (
    <section>
      <h2>Edit {entityName}</h2>
      <DetailBuilder
        defaultValue={data}
        submitStatus={submitStatus}
        deleteStatus={deleteStatus}
        onSubmit={(entity) => editDetail({ agentName, entity })}
        onDelete={() => {
          deleteDetail({ agentName, entityName });
        }}
      />
    </section>
  );
}
export default DetailEdit;

import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, queryCache } from "react-query";
import slugify from "@sindresorhus/slugify";
import cx from "classnames";

import { getAgents, updateAgent, deleteAgent, getIntents } from "../api/agent";

import { Form, Field, Label, Input } from "./Form";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import Text from "../styles/text.module.css";
import Cluster from "../styles/cluster.module.css";
import Loading from "./Loading";
import Card from "./Card";
import Intents from "./IntentsList";
import { useNotify } from "./NotifyContext";
import Confirm from "./Confirm";
import Alert from "./Alert";
import { ReactComponent as Pencil } from "../icons/pencil-square.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";
import { ReactComponent as ArrowUp } from "../icons/arrow-circle-up.svg";
import { ReactComponent as DocumentAdd } from "../icons/document-add.svg";

function AgentsDashboard() {
  const { status, data } = useQuery("agents", getAgents, {
    initialData: () => queryCache.getQueryData("agents"),
  });

  return (
    <div className={Stack.lg}>
      <h1 className={Text.title}>Your active agents</h1>
      <div className={Stack.lg}>
        {status === "error" && (
          <Alert>Something went wrong loading your agents</Alert>
        )}
        {status === "loading" && (
          <Loading size="sm">Loading your agents</Loading>
        )}
        {status === "success" &&
          (data.length ? (
            data.map((agent) => (
              <AgentCard
                key={agent.name}
                agent={agent}
                showPublish={agent.unpublished}
              />
            ))
          ) : (
            <>
              <p>
                Welcome to your dashboard, this is where all your agents will
                appear once they have been created.
              </p>
              <p>
                You have not created any agents yet, click the button below to
                get started.
              </p>
              <Link className={cx(Button.secondary, Button.lg)} to="agents/new">
                Create new agent <DocumentAdd className="icon-sm" />
              </Link>
            </>
          ))}
      </div>
    </div>
  );
}

function AgentCard({ agent, showPublish }) {
  const [renameAgent] = useMutation(updateAgent);
  const { status, error, data } = useQuery(
    ["intents", agent.name],
    getIntents,
    {
      initialData: () => queryCache.getQueryData(["intents", agent.name]),
    }
  );

  return (
    <Card columnCount="three">
      <div className={Stack.lg}>
        <EditName
          agentName={agent.name}
          displayName={agent.displayName}
          renameAgent={renameAgent}
        />
        <div className={Cluster.md}>
          <DeleteAgent agent={agent} />
          {
            // Only render publish button when changes have been made to the agent, and there is a greeting, a default unrecognized intent, and at least one user created intent
            showPublish && data && data.length >= 3 && (
              <Link to={`publish/${agent.name}`} className={Button.primary}>
                Publish agent <ArrowUp className="icon-sm" />
              </Link>
            )
          }
        </div>
      </div>
      <Intents
        agentName={agent.name}
        status={status}
        data={data}
        error={error}
      />
    </Card>
  );
}

function EditName({ agentName, displayName, renameAgent }) {
  const [editingName, setEditingName] = React.useState(false);
  const toggleEditingName = () => {
    setEditingName(!editingName);
  };

  return (
    <>
      {editingName ? (
        <Form
          resetOnSubmit={false}
          onSubmit={({ newAgentName }) => {
            const name = slugify(newAgentName);
            renameAgent(
              { agentName, name, displayName: newAgentName },
              {
                onSuccess: () => {
                  queryCache
                    .invalidateQueries("agents")
                    .then(() => toggleEditingName());
                },
              }
            );
          }}
        >
          <Field id="newAgentName" className={Stack.md}>
            <Label className={Text.cardTitle}>Rename your agent</Label>
            <div className={Cluster.inputRow}>
              <Input
                required
                // at least one non-whitespace character
                pattern=".*\S.*"
                errors={{
                  valueMissing: "Please enter a name for your agent",
                  patternMismatch: "Please enter a name for your agent",
                }}
                defaultValue={displayName}
              />
              <button
                type="submit"
                className={Button.icon}
                onClick={renameAgent}
                aria-label={"Save agent name"}
              >
                <Check className="icon-sm" />
              </button>
            </div>
          </Field>
        </Form>
      ) : (
        <div className={Cluster.xs}>
          <h2>{displayName}</h2>
          <button
            className={Button.icon}
            onClick={toggleEditingName}
            aria-label={"Edit agent name"}
          >
            <Pencil className="icon-sm" />
          </button>
        </div>
      )}
    </>
  );
}

function DeleteAgent({ agent }) {
  const [removeAgent] = useMutation(deleteAgent);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const setNotification = useNotify();
  return (
    <>
      <button className={Button.danger} onClick={() => setShowConfirm(true)}>
        Delete agent <Trash className="icon-sm" />
      </button>
      <Confirm
        id={"deleteAgent" + agent.name}
        isOpen={showConfirm}
        onCancel={() => setShowConfirm(false)}
        confirmLabel="Delete"
        onConfirm={() => {
          setShowConfirm(false);
          removeAgent(agent.name, {
            onSuccess: () => {
              queryCache.invalidateQueries("agents");
              setNotification(`Successfully deleted ${agent.displayName}!`);
            },
          });
        }}
      >
        Are you sure you want to delete {agent.displayName}?
      </Confirm>
    </>
  );
}
export default AgentsDashboard;

import React from "react";

import { useUpdateUser } from "../api/user";
import Loading from "./Loading";
import Alert from "./Alert";
import { Form, Field, Label, Input } from "./Form";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";

function UpdateUserDetails({ name, phone, company }) {
  let [updateUser, { status }] = useUpdateUser();
  return (
    <Form onSubmit={updateUser}>
      <div className={Stack.md}>
        <Field id="name">
          <Label>Name</Label>
          <Input name="name" defaultValue={name} required />
        </Field>
        <Field id="company">
          <Label>Company</Label>
          <Input name="company" defaultValue={company} required />
        </Field>
        <Field id="phone">
          <Label>Phone number</Label>
          <Input type="tel" name="phone" defaultValue={phone} required />
        </Field>
        <button
          type="submit"
          className={Button.secondary}
          disabled={status === "loading"}
        >
          Update
          <Loading status={status} size="sm" />
        </button>
        {status === "success" && (
          <Alert type="success" dismissable={true}>
            Account updated
          </Alert>
        )}
        {status === "error" && (
          <Alert>Something went wrong updating your account</Alert>
        )}
      </div>
    </Form>
  );
}

export default UpdateUserDetails;

import React from "react";

import { Form, Field, Label, Input } from "./Form";
import Text from "../styles/text.module.css";
import Cluster from "../styles/cluster.module.css";
import Button from "../styles/button.module.css";

function IntentName({ defaultValue, ...rest }) {
  return (
    <Form {...rest}>
      <Field id="intentName">
        <Label className={Text.cardTitle}>Name your request</Label>
        <div className={Cluster.inputRow}>
          <Input
            required
            defaultValue={defaultValue}
            // at least one non-whitespace character, and should not end with _confirm or _reject
            pattern="^(?!.*(_confirm|_reject)$).*\S.*$"
            errors={{
              valueMissing: "Please enter a name for your customer enquiry",
              patternMismatch: "Name can't end in 'confirm' or 'reject'",
            }}
            placeholder="e.g. Deliveries"
          />
          <button type="submit" className={Button.secondary}>
            Save
          </button>
        </div>
      </Field>
    </Form>
  );
}

export default IntentName;

import React from "react";
import { Link } from "react-router-dom";
import { useMutation, queryCache } from "react-query";

import { deleteIntent } from "../api/agent";
import { invalidateAgentsIfFirstChange } from "../api/intent";
import Loading from "./Loading";
import Alert from "./Alert";
import Confirm from "./Confirm";
import { useNotify } from "./NotifyContext";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import Cluster from "../styles/cluster.module.css";
import { ReactComponent as Pencil } from "../icons/pencil-square.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";

function Intents({ agentName, status, data, error }) {
  const removeConfirmationIntents = (intent) => {
    //check if intent.name has the strings _confirm or _reject
    const confirmationRegex = new RegExp(/_confirm$|_reject$/);
    const isConfirmationIntent = confirmationRegex.test(intent.name);
    //return false if it is a confirmation intent
    return !isConfirmationIntent;
  };

  if (status === "loading") {
    return (
      <Loading size="sm">
        <span>Loading</span>
      </Loading>
    );
  }

  if (status === "error")
    return (
      <div>
        <Alert>Something went wrong loading your requests</Alert>
      </div>
    );

  const greeting = data.find((intent) => intent.name === "greeting");
  const intents = data
    .filter(removeConfirmationIntents)
    .filter((intent) => intent.name !== "greeting")
    .filter((intent) => intent.name !== "default-unrecognised-intent");

  return (
    <>
      <IntentsList
        agentName={agentName}
        title="Agent greeting"
        status={status}
        error={error}
        data={[greeting]}
        emptyMsg="No greeting yet"
        addIntent={
          !greeting && (
            <Link
              className={Button.default}
              to={`./agents/${agentName}/greetings/new`}
            >
              Add greeting
            </Link>
          )
        }
      ></IntentsList>
      <IntentsList
        agentName={agentName}
        title="Customer requests"
        status={status}
        error={error}
        data={intents}
        emptyMsg="No requests yet"
        addIntent={
          greeting && (
            <Link className={Button.default} to={`./agents/${agentName}/new`}>
              Add new request
            </Link>
          )
        }
      ></IntentsList>
    </>
  );
}

function IntentsList({ agentName, title, status, data, emptyMsg, addIntent }) {
  return (
    <div className={Stack.lg}>
      <h3>{title}</h3>
      <div className={Stack.md}>
        {status === "success" && (
          <ul>
            {!data[0] ? (
              <li>{emptyMsg}</li>
            ) : (
              data.map((intent) => {
                return (
                  <li key={intent.name} className={Cluster.xs}>
                    {intent.displayName}
                    <Link
                      className={Button.icon}
                      aria-label={`Edit ${agentName}`}
                      to={
                        intent.name === "greeting"
                          ? `./agents/${agentName}/greetings/edit`
                          : `./agents/${agentName}/${intent.name}`
                      }
                    >
                      <Pencil className="icon-sm" />
                    </Link>
                    {intent.name === "greeting" ? null : (
                      <DeleteIntent intent={intent} agentName={agentName} />
                    )}
                  </li>
                );
              })
            )}
          </ul>
        )}
        {addIntent}
      </div>
    </div>
  );
}
function DeleteIntent({ intent, agentName }) {
  const [removeIntent, { status }] = useMutation(deleteIntent);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const setNotification = useNotify();
  return (
    <>
      <button
        className={Button.icon}
        aria-label={`Delete intent ${intent.displayName}`}
      >
        <Trash className="icon-sm" onClick={() => setShowConfirm(true)} />
        <Loading status={status} size="sm" />
      </button>
      <Confirm
        id={"deleteIntent" + intent.name}
        isOpen={showConfirm}
        onCancel={() => setShowConfirm(false)}
        confirmLabel="Delete"
        onConfirm={() => {
          setShowConfirm(false);
          removeIntent(
            { agentName, mainIntent: intent },
            {
              onSuccess: () => {
                invalidateAgentsIfFirstChange(agentName);
                queryCache.invalidateQueries(["intents", agentName]);
                setNotification(`Successfully deleted ${intent.displayName}!`);
              },
            }
          );
        }}
      >
        Are you sure you want to delete {intent.displayName}?
      </Confirm>
    </>
  );
}
export default Intents;

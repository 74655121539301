export let initialRejectIntent = {
  phrases: ["no", "cancel", "reject"],
  outputContexts: ["default"],
  emit: [["OK, let's try that again"]],
};

export let initialConfirmIntent = {
  phrases: [
    "confirm",
    "yes",
    "correct",
    "I would like to confirm",
    "please confirm",
  ],
};

export let defaultDoneIntent = {
  name: "Goodbye",
  displayName: "Goodbye",
  description: "Saying goodbye is always the hardest part",
  phrases: [
    "I am good",
    "I am done",
    "No thank you",
    "I dont need anything else",
    "I am finised",
    "I have finished",
    "No, I dont need anything else",
  ],
  isTerminal: true,
  emit: [["OK, thank you for calling!"]],
};

export let defaultUnrecognisedIntent = {
  name: "default-unrecognised-intent",
  displayName: "default unrecognised intent",
  isFallback: true,
  confirm: false,
  emit: [
    [
      "Sorry I didn't understand that",
      "Sorry, I didn't get that",
      "Sorry, I'm having difficulty understanding that",
    ],
    [
      "It is me, not you that is the problem.",
      "I'm a very basic agent.",
      "I'm trying my best but I'm not as smart as a human.",
      "Don't worry, a human will be told that you called and the number you called from, but lets try again.",
    ],
  ],
};

const intents = {
  initialRejectIntent,
  initialConfirmIntent,
  defaultDoneIntent,
  defaultUnrecognisedIntent,
};

export let defaultIntents = Object.entries(intents)
  .filter((intent) => intent[0].match(/^default/))
  .map((intent) => intent[1]);

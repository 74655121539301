import React from "react";
import { useParams } from "react-router-dom";

import { useIntent } from "../api/intent";
import IntentBuilder from "./IntentBuilder";
import Loading from "./Loading";
import Alert from "./Alert";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";

function IntentEdit() {
  const { agentName, intentName } = useParams();

  const { status, dispatch, intent } = useIntent({
    agentName,
    intentName,
  });

  const {
    status: confirmIntentStatus,
    dispatch: dispatchConfirmIntent,
    intent: confirmIntent,
  } = useIntent({
    agentName,
    intentName: intentName + "_confirm",
  });

  const isError = status === "error" || confirmIntentStatus === "error";

  const isLoading = status === "loading" || confirmIntentStatus === "loading";

  const isSuccess = status === "success" && confirmIntentStatus === "success";

  return (
    <section className={Stack.lg} style={{ minWidth: "45ch" }}>
      <h1 className={Text.title}>Edit a request</h1>
      {isError && <Alert>Something went wrong loading your request</Alert>}
      {isLoading && (
        <Loading size="sm">
          <span>Loading the request</span>
        </Loading>
      )}
      {isSuccess && (
        <IntentBuilder
          intent={intent}
          dispatch={dispatch}
          confirmIntent={confirmIntent}
          dispatchConfirmIntent={dispatchConfirmIntent}
        />
      )}
    </section>
  );
}

export default IntentEdit;

import React from "react";
import cx from "classnames";
import slugify from "@sindresorhus/slugify";
import { Form, Field, Info, Label, Input, Textarea } from "./Form";
import Loading from "./Loading";
import Confirm from "./Confirm";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Cluster from "../styles/cluster.module.css";
import { ReactComponent as Trash } from "../icons/trash.svg";

function reducer(values, action) {
  switch (action.type) {
    case "new":
      const newValues = [...values, emptyValue];
      return newValues;
    case "delete":
      return [
        ...values.slice(0, action.index),
        ...values.slice(action.index + 1),
      ];
    case "update_value":
      return values.map((v, i) => {
        return { ...v, value: i === action.index ? action.value : v.value };
      });
    case "update_synonyms":
      return values.map((v, i) => {
        return {
          ...v,
          synonyms:
            i === action.index ? action.synonyms.split(",") : v.synonyms,
        };
      });
    default:
      throw new Error(`Action '${action.type}' not found`);
  }
}

const emptyValue = { value: "", synonyms: [] };

function DetailBuilder({
  defaultValue,
  submitStatus,
  onSubmit,
  onDelete,
  deleteStatus,
}) {
  const [values, dispatch] = React.useReducer(
    reducer,
    defaultValue?.values || [emptyValue]
  );

  const [searchParams] = useSearchParams();
  const highlight = searchParams.get("slot");

  //condition to check if there is an entityName in the url
  //make post or put request appropriately
  const detailSubmit = (data, event) => {
    const valuesToSubmit = values
      .filter((v) => v.value)
      .map((v) => ({
        value: v.value,
        synonyms: v.synonyms.map((s) => s.trim()).filter((s) => s),
      }));
    const name = slugify(data.detailType);
    const displayName = data.detailType;
    const detailsObject = {
      type: name,
      name,
      displayName,
      //KIND_MAP specifies that it's a user defined entity. api spec requires the kind property to be set
      kind: "KIND_MAP",
      values: valuesToSubmit,
    };

    onSubmit(detailsObject);
  };

  const [showConfirm, setShowConfirm] = React.useState(false);

  return (
    <Form onSubmit={detailSubmit}>
      <div className={Stack.lg}>
        <Field id="detailType">
          <Label>Enter a type name</Label>
          <Info>
            Describe the type of detail you want to extract from your customers,
            e.g. mood
          </Info>
          <Input
            required
            pattern=".*\S.*"
            errors={{
              valueMissing: "Please enter a type for your detail",
              patternMismatch: "Please enter a type for your detail",
            }}
            defaultValue={defaultValue ? defaultValue.displayName : ""}
          />
        </Field>
        <div className={Stack.sm}>
          {values.map((value, index) => (
            <ValueInputs
              key={index}
              {...value}
              index={index}
              dispatch={dispatch}
            >
              {values.length > 1 && (
                <DeleteButton
                  onClick={() => dispatch({ type: "delete", index })}
                />
              )}
            </ValueInputs>
          ))}
          <button
            type="button"
            className={cx(Button.default, Button.fullWidth)}
            onClick={() => dispatch({ type: "new" })}
          >
            Add new value +
          </button>
        </div>
        <div className={Cluster.md}>
          <Link
            className={Button.default}
            to={
              highlight
                ? {
                    pathname: "..",
                    search: "?" + searchParams,
                  }
                : ".."
            }
          >
            Go Back
          </Link>
          <button type="submit" className={Button.primary}>
            Save detail <Loading status={submitStatus} size="sm" />
          </button>
          {!onDelete ? null : (
            <>
              <button
                type="button"
                className={Button.danger}
                onClick={() => setShowConfirm(true)}
                style={{ marginLeft: "auto" }}
              >
                Delete detail <Loading status={deleteStatus} size="sm" />
              </button>
              <Confirm
                id={"deleteIntent" + defaultValue.name}
                isOpen={showConfirm}
                onCancel={() => setShowConfirm(false)}
                confirmLabel="Delete"
                onConfirm={() => {
                  setShowConfirm(false);
                  onDelete();
                }}
              >
                Are you sure you want to delete {defaultValue.displayName}?
              </Confirm>
            </>
          )}
        </div>
      </div>
    </Form>
  );
}

function ValueInputs({ index, value, synonyms, dispatch, children, ...rest }) {
  return (
    <fieldset {...rest} key={index}>
      <legend className="vh">Value {index + 1}</legend>
      <div className={Cluster.md} style={{ "--align": "flex-start" }}>
        <Field
          id={"value_" + index}
          style={{ flex: "1 0 45%" }}
          hideLabels={index > 0}
        >
          <Label>Enter a value</Label>
          <Info>Choose a value for the detail</Info>
          <Input
            placeholder="e.g. happy"
            required={index === 0}
            autoFocus={index > 0}
            pattern={index === 0 ? ".*\\S.*" : undefined}
            value={value}
            errors={{
              valueMissing: "Please enter a value for this detail",
              patternMismatch: "Please enter a value for this detail",
            }}
            onChange={(e) =>
              dispatch({ type: "update_value", index, value: e.target.value })
            }
            onBlur={(e) => {
              //condition is !synonyms[0] instead of synonyms.length because String.split() returns an array with an empty string, not an empty array if the input is an empty string
              !synonyms[0] &&
                value.length &&
                dispatch({
                  type: "update_synonyms",
                  index,
                  synonyms: e.target.value + ",",
                });
            }}
          />
        </Field>
        <Field
          id={"synonyms_" + index}
          style={{ flex: "1 0 45%" }}
          hideLabels={index > 0}
        >
          <Label>Enter some synonyms for your value</Label>
          <Info>Separate them with commas</Info>
          <Textarea
            placeholder="e.g. happy, ecstatic, overjoyed"
            required={index === 0}
            pattern={index === 0 && ".*\\S.*"}
            errors={{
              valueMissing: "Please enter a value for this detail",
              patternMismatch: "Please enter a value for this detail",
            }}
            rows={Math.floor(synonyms.length / 3) + 1}
            value={synonyms.join(",")}
            onChange={(e) => {
              dispatch({
                type: "update_synonyms",
                index,
                synonyms: e.target.value,
              });
            }}
          />
        </Field>
        {children}
      </div>
    </fieldset>
  );
}

function DeleteButton({ dispatch, ...rest }) {
  return (
    <button
      {...rest}
      style={{ alignSelf: "center " }}
      className={Button.icon}
      type="button"
    >
      <Trash className="icon-sm" />
    </button>
  );
}
export default DetailBuilder;

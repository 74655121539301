import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Field, Label, Info, Input } from "./Form";
import {
  EditableField,
  EditableInput,
  EditButton,
  DeleteButton,
} from "./EditableInput";
import { useIntent } from "../api/intent";

import Loading from "./Loading";
import Card from "./Card";
import Alert from "./Alert";
import Text from "../styles/text.module.css";
import Columns from "../styles/Columns.module.css";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Cluster from "../styles/cluster.module.css";

function AgentGreetingsBuilder({ isCreating }) {
  const { agentName } = useParams();
  const navigate = useNavigate();
  const { status, dispatch, intent } = useIntent({
    agentName,
    intentName: "greeting",
  });

  const onNext = () => {
    if (isCreating) {
      navigate(`/agents/${agentName}/new`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className={Stack.lg}>
      <h1 className={Text.title}>Welcome your customers</h1>
      <Card columnCount="one">
        {status === "error" && (
          <Alert dismissable={true}>
            Something went wrong loading your greetings
          </Alert>
        )}
        {status === "loading" && (
          <Loading size="sm">
            <span>Loading your greetings</span>
          </Loading>
        )}
        {status === "success" && (
          <div className={Stack.lg}>
            <div className={Columns.two}>
              <Form
                onSubmit={(data) => {
                  dispatch({ type: "add_emit", value: data.addGreeting });
                }}
              >
                <div className={Stack.lg}>
                  <Field id="addGreeting" className={Stack.md}>
                    <Label className={Text.cardTitle}>
                      {isCreating ? "Add greetings" : "Edit your greetings"}
                    </Label>
                    <Info>
                      How would you like your agent to answer the phone? Add as
                      many variations of your greeting as you wish.
                    </Info>
                    <div className={Cluster.inputRow}>
                      <Input required />
                      <button type="submit" className={Button.secondary}>
                        Add +
                      </button>
                    </div>
                  </Field>
                  {intent.emit[0]?.length ? (
                    <button
                      type="button"
                      className={Button.primary}
                      onClick={onNext}
                    >
                      {isCreating ? "Next" : "Back to dashboard"}
                    </button>
                  ) : null}
                </div>
              </Form>
              <GreetingList
                greetings={intent.emit[0] || []}
                dispatch={dispatch}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

function GreetingList({ greetings, dispatch }) {
  if (!greetings.length) return null;
  return (
    <div className={Stack.md}>
      <h3 className={Text.cardTitle}>Your greetings</h3>
      <ul className={Stack.sm}>
        {greetings.map((greeting, index) => (
          <li key={greeting}>
            <EditableField
              label={`Greeting ${index}`}
              onEdit={(value) => dispatch({ type: "edit_emit", index, value })}
            >
              <EditableInput defaultValue={greeting} />
              <EditButton />
              <DeleteButton
                onClick={() => dispatch({ type: "delete_emit", index })}
              />
            </EditableField>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AgentGreetingsBuilder;

import firebase, { googleProvider } from "./firebase";
import { createClient } from "./client";

const client = createClient({ auth: false });

function getAllSpreadsheets() {
  const run = () =>
    getToken().then((token) => {
      const driveUrl = `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.spreadsheet'`;
      return client(driveUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    });
  // if error is a 401 will reauth then try once more
  return run().catch(retry(run));
}

function getSpreadsheet(key, id) {
  const run = () =>
    getToken().then((token) => {
      const sheetsUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}`;
      return client(sheetsUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    });
  return run().catch(retry(run));
}

function getToken() {
  const token = window.localStorage.getItem("googleToken");
  if (token) {
    return Promise.resolve(token);
  } else {
    return firebase
      .auth()
      .currentUser.reauthenticateWithPopup(googleProvider)
      .then((user) => {
        window.localStorage.setItem("googleToken", user.credential.accessToken);
        return user.credential.accessToken;
      });
  }
}

function retry(promiseFn) {
  return (error) => {
    if (error.status === 401) {
      window.localStorage.removeItem("googleToken");
      return promiseFn();
    } else {
      throw error;
    }
  };
}

export { getAllSpreadsheets, getSpreadsheet };

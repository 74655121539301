import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useAuth } from "./api/auth";

import Header from "./components/Header";
import UserHeader from "./components/UserHeader";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import AgentsDashboard from "./components/AgentsDashboard";
import AgentName from "./components/AgentName";
import AgentGreetingsBuilder from "./components/AgentGreetingsBuilder";
import IntentNew from "./components/IntentNew";
import IntentEdit from "./components/IntentEdit";
import DetailsChoose from "./components/DetailsChoose";
import NewDetailButton from "./components/NewDetailButton";
import DetailNew from "./components/DetailNew";
import DetailEdit from "./components/DetailEdit";
import AgentTest from "./components/AgentTest";
import AgentPublish from "./components/AgentPublish";
import Profile from "./components/Profile";
import Notify from "./components/Notify";
import { NotifyContext } from "./components/NotifyContext";

function App() {
  const status = useAuth();
  // have to wait for Firebase otherwise logged in users see a flash of logged out view
  if (status === "loading") return null;
  if (status === "loggedOut")
    return (
      <Router>
        <Routes>
          <Page path="sign-up" title="Create an account" element={<SignUp />} />
          <Page path="*" title="Log in to your account" element={<LogIn />} />
        </Routes>
      </Router>
    );
  return (
    <Layout>
      <Page path="/" title="Your Dashboard" element={<AgentsDashboard />} />
      <Page
        path="agents/new"
        title="Create a new agent"
        element={<AgentName />}
      />
      <Page
        path="agents/:agentName/greetings/new"
        title="Welcome your customers"
        element={<AgentGreetingsBuilder isCreating={true} />}
      />
      <Page
        path="agents/:agentName/greetings/edit"
        title="Welcome your customers"
        element={<AgentGreetingsBuilder isCreating={false} />}
      />
      <Page
        path="agents/:agentName/new"
        title="Create a request"
        element={<IntentNew />}
      />
      <Page
        path="agents/:agentName/:intentName"
        title="Edit a request"
        element={<IntentEdit />}
      >
        <Page
          path="details"
          element={<DetailsChoose highlightType="request" />}
        >
          <Page path="/" element={<NewDetailButton />} />
          <Page path="new" element={<DetailNew />} />
          <Page path=":entityName" element={<DetailEdit />} />
        </Page>
        <Page
          path="response-details"
          element={<DetailsChoose highlightType="response" />}
        />
        <Page
          path="confirm-response-details"
          element={<DetailsChoose highlightType="confirm-response" />}
        />
      </Page>
      <Page
        path="test/:agentName"
        title="Test your agent"
        element={<AgentTest />}
      />
      <Page
        path="publish/:agentName"
        title="Publish your agent"
        element={<AgentPublish />}
      />
      <Page path="/profile" title="Your profile" element={<Profile />} />
      <Page path="*" title="Not found" element={<h1>Not found</h1>} />
    </Layout>
  );
}

const BASE_TITLE = "SMB Connect";

function Page({ title, ...rest }) {
  React.useEffect(() => {
    document.title = title ? `${title} | ${BASE_TITLE}` : BASE_TITLE;
  }, [title]);
  return <Route {...rest} />;
}

function Layout({ children }) {
  const [notification, setNotification] = React.useState("");
  return (
    <Router>
      <div className="page-layout">
        <Header />
        <Routes>
          <Route path="/" element={<UserHeader />} />
        </Routes>
        <main>
          <NotifyContext.Provider value={setNotification}>
            <Routes>{children}</Routes>
            {notification && <Notify>{notification}</Notify>}
          </NotifyContext.Provider>
        </main>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "./Card";
import TemplatePhrase from "./TemplatePhrase";
import { Form, Field, Label, Info, Input } from "./Form";
import Stack from "../styles/stack.module.css";
import Cluster from "../styles/cluster.module.css";
import Button from "../styles/button.module.css";
import Text from "../styles/text.module.css";

function ConfirmRespond({
  emit,
  hasConfirmations,
  dispatch,
  isEndOfConversation,
  confirmIntent,
  dispatchConfirmIntent,
  slots,
}) {
  return (
    <>
      <Card>
        <>
          <div className={Stack.lg}>
            <ToggleConfirmations
              value={hasConfirmations ? "yes" : "no"}
              onChange={(e) => {
                // swap emit arrays from main intent and confirm intent on toggle
                // so we preserve both of them
                dispatch({
                  type: "toggle_confirmations",
                  enabled: e.target.value === "yes",
                  responses: confirmIntent.emit,
                }).then(() => {
                  dispatchConfirmIntent({
                    type: "set_emit",
                    responses: emit,
                  });
                });
              }}
            />
            {hasConfirmations ? (
              <AddResponses
                title="How should your agent confirm the request?"
                id="addConfirmation"
                onSubmit={(data) => {
                  dispatch({
                    type: "add_emit",
                    value: data.addConfirmation,
                  });
                }}
              />
            ) : null}
          </div>
          {hasConfirmations ? (
            <EditResponses
              title="Confirmations"
              isConfirm={true}
              responses={emit[0]}
              onDelete={(index) => dispatch({ type: "delete_emit", index })}
              onEdit={(index, value) =>
                dispatch({ type: "edit_emit", index, value })
              }
              slots={slots}
            />
          ) : null}
        </>
      </Card>
      <Card>
        <>
          <AddResponses
            title={
              hasConfirmations
                ? "How would you like your agent to respond after confirmation?"
                : "How would you like your agent to respond?"
            }
            id="addResponse"
            onSubmit={(data) => {
              const action = {
                type: "add_emit",
                value: data.addResponse,
              };
              hasConfirmations
                ? dispatchConfirmIntent(action)
                : dispatch(action);
            }}
          />
          <div className={Stack.lg}>
            <EditResponses
              title="Responses"
              isConfirm={false}
              responses={hasConfirmations ? confirmIntent.emit[0] : emit[0]}
              slots={slots}
              onDelete={(index) => {
                const action = { type: "delete_emit", index };
                hasConfirmations
                  ? dispatchConfirmIntent(action)
                  : dispatch(action);
              }}
              onEdit={(index, value) =>
                dispatch({ type: "edit_emit", index, value })
              }
            />
            <ToggleEndConversation
              value={isEndOfConversation ? "yes" : "no"}
              onChange={(e) => {
                hasConfirmations
                  ? dispatchConfirmIntent({
                      type: "toggle_end_conversation",
                      endConversation: e.target.value === "yes",
                    })
                  : dispatch({
                      type: "toggle_end_conversation",
                      endConversation: e.target.value === "yes",
                    });
              }}
            />
          </div>
        </>
      </Card>
    </>
  );
}

function ToggleConfirmations({ value, onChange, ...rest }) {
  return (
    <fieldset {...rest}>
      <div className={Stack.sm}>
        <legend className={Text.cardTitle}>Should your agent confirm?</legend>
        <div className={Cluster.md}>
          <Field id="yes">
            <Input
              type="radio"
              name="confirm"
              value="yes"
              checked={value === "yes"}
              onChange={onChange}
            />
            <Label>Yes</Label>
          </Field>
          <Field id="no">
            <Input
              type="radio"
              name="confirm"
              value="no"
              checked={value === "no"}
              onChange={onChange}
            />
            <Label>No</Label>
          </Field>
        </div>
      </div>
    </fieldset>
  );
}

function AddResponses({ title, id, ...rest }) {
  return (
    <Form {...rest}>
      <Field id={id}>
        <Label className={Text.cardTitle}>{title}</Label>
        <Info>Add as many phrases as you like</Info>
        <div className={Cluster.inputRow}>
          <Input required />
          <button type="submit" className={Button.secondary}>
            Add +
          </button>
        </div>
      </Field>
    </Form>
  );
}

function EditResponses({
  responses,
  isConfirm,
  onDelete,
  onEdit,
  title,
  slots,
}) {
  const navigate = useNavigate();

  if (!responses?.length) return null;
  return (
    <div className={Stack.md}>
      <h3>{title}</h3>
      <p>Highlight the details you want your agent to insert.</p>

      <ul className={Stack.md}>
        {responses.map((response, index) => (
          <TemplatePhrase
            key={response + index}
            phrase={response}
            slots={slots}
            index={index}
            onEdit={(value) => onEdit(index, value)}
            onDelete={() => onDelete(index)}
            onHighlight={(selection) => {
              navigate({
                pathname: isConfirm
                  ? "confirm-response-details"
                  : "response-details",
                search:
                  "?" +
                  new URLSearchParams({
                    slot: selection,
                    request: index,
                  }),
              });
            }}
          />
        ))}
      </ul>
    </div>
  );
}

function ToggleEndConversation({ value, onChange, ...rest }) {
  return (
    <fieldset {...rest}>
      <div className={Stack.sm}>
        <legend className={Text.cardTitle}>
          Should your agent end the conversation here?
        </legend>
        <div className={Cluster.md}>
          <Field id="yes_end_conversation">
            <Input
              type="radio"
              name="end_conversation"
              value="yes"
              checked={value === "yes"}
              onChange={onChange}
            />
            <Label>Yes</Label>
          </Field>
          <Field id="no_end_conversation">
            <Input
              type="radio"
              name="end_conversation"
              value="no"
              checked={value === "no"}
              onChange={onChange}
            />
            <Label>No</Label>
          </Field>
        </div>
      </div>
    </fieldset>
  );
}
export default ConfirmRespond;

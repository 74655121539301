import React from "react";
import { useOutlet, Link, useNavigate, useParams } from "react-router-dom";
import cx from "classnames";
import slugify from "@sindresorhus/slugify";

import Card from "./Card";
import IntentName from "./IntentName";
import { AddPhrase, Phrases } from "./IntentPhrases";
import { Prompts } from "./DetailsPrompts";
import DetailsList from "./DetailsList";
import { updateIntentName } from "../api/intent";
import ConfirmRespond from "./IntentConfirmRespond";
import EndConversation from "./EndConversation";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";
import Modal from "../styles/modal.module.css";
import Box from "../styles/box.module.css";

function IntentBuilder({
  intent,
  dispatch,
  confirmIntent,
  dispatchConfirmIntent,
}) {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { agentName, intentName } = useParams();

  let allSubstitutions = [
    ...intent.phrases,
    ...intent.emit.reduce((m, flat) => [...m, ...flat], []),
  ]
    .join("")
    .matchAll(/\$\{([a-zA-Z][a-zA-Z0-9]+)\}/g);
  console.log({ allSubstitutions });
  let usedDetails = new Map(
    Array.from(allSubstitutions).map((arr) => [arr[1], arr[0]])
  );

  return (
    <>
      <Card>
        <div className={Stack.lg}>
          <IntentName
            defaultValue={intent.displayName}
            onSubmit={(data) => {
              const name = slugify(data.intentName);
              const displayName = data.intentName;
              updateIntentName({
                agentName,
                intentName,
                intent: { ...intent, name, displayName },
                confirmIntent: {
                  ...confirmIntent,
                  name: name + "_confirm",
                  displayName: name + "_confirm",
                },
                rejectIntent: {
                  name: name + "_reject",
                  displayName: name + "_reject",
                },
              }).then(() => navigate(`../${name}`, { replace: true }));
            }}
            resetOnSubmit={false}
          />
          <AddPhrase
            onSubmit={(data) =>
              dispatch({ type: "add_phrase", phrase: data.addPhrase })
            }
          />
        </div>
        <Phrases
          phrases={intent.phrases}
          slots={intent.slots}
          deletePhrase={(index) => dispatch({ type: "delete_phrase", index })}
          editPhrase={(index, value) =>
            dispatch({ type: "edit_phrase", index, value })
          }
        />
      </Card>
      <Card>
        <section className={Stack.lg}>
          <h2 className={Text.cardTitle}>Details</h2>
          {!intent?.slots.length ? (
            <div>No details added yet</div>
          ) : (
            <DetailsList
              slots={intent.slots}
              dispatch={dispatch}
              detailsMap={usedDetails}
            />
          )}
          <Link className={Button.secondary} to="details">
            New required detail +
          </Link>
        </section>
        {!intent?.slots.length ? null : (
          <section className={Stack.lg}>
            {intent.slots.map((slot, index) => (
              <Prompts
                key={`prompt_${index}`}
                index={index}
                prompts={slot.prompts}
                slotName={slot.name}
                deletePrompt={(promptIndex) =>
                  dispatch({
                    type: "delete_prompt",
                    slotName: slot.name,
                    index: promptIndex,
                  })
                }
                editPrompt={(index, value) => {
                  dispatch({
                    type: "edit_prompt",
                    slotName: slot.name,
                    index,
                    value,
                  });
                }}
              />
            ))}
          </section>
        )}
      </Card>
      <ConfirmRespond
        dispatch={dispatch}
        emit={intent.emit}
        hasConfirmations={intent.outputContexts.length}
        isEndOfConversation={
          intent.outputContexts.length
            ? confirmIntent.isTerminal
            : intent.isTerminal
        }
        confirmIntent={confirmIntent}
        dispatchConfirmIntent={dispatchConfirmIntent}
        slots={intent.slots}
      />
      <EndConversation
        dispatch={dispatch}
        intent={intent}
        confirmIntent={confirmIntent}
        dispatchConfirmIntent={dispatchConfirmIntent}
      />
      {outlet ? (
        <div className={cx(Box.default, Modal.modal)}>{outlet}</div>
      ) : null}
    </>
  );
}

export default IntentBuilder;

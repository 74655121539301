import React from "react";
import cx from "classnames";
import Button from "../styles/button.module.css";
import Styles from "../styles/Alert.module.css";
import { ReactComponent as ExclamationCircle } from "../icons/exclamation-circle.svg";
import { ReactComponent as CheckCircle } from "../icons/check-circle.svg";
import { ReactComponent as Exclamation } from "../icons/exclamation.svg";
import { ReactComponent as Cross } from "../icons/cross.svg";

const icons = {
  warning: <Exclamation className="icon" title="Warning" />,
  success: <CheckCircle className="icon" title="Success" />,
  danger: <ExclamationCircle className="icon" title="Danger" />,
};

function Alert({
  type = "danger",
  className,
  dismissable = false,
  timeout = 300,
  ...rest
}) {
  // "visible" | "fading" | "hidden"
  const [visibility, setVisibility] = React.useState("visible");

  // remove from page after timeout
  // gives animation time to complete
  React.useEffect(() => {
    let id;
    if (visibility === "fading") {
      id = setTimeout(() => setVisibility("hidden"), timeout);
    }
    return () => window.clearTimeout(id);
  }, [visibility, timeout]);

  if (visibility === "hidden") return null;

  return (
    <div
      className={cx(className, Styles[type], {
        // dismiss button starts the fade out by setting this class
        [Styles.fadeOut]: visibility === "fading",
      })}
      style={{ "--timeout": timeout + "ms" }}
      role="alert"
    >
      {icons[type]}
      <div {...rest} />
      {dismissable && (
        <button
          onClick={() => setVisibility("fading")}
          aria-label="Dismiss alert"
          className={cx(Button.icon, Styles.button)}
        >
          <Cross className="icon" />
        </button>
      )}
    </div>
  );
}

export default Alert;

import React from "react";
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import cx from "classnames";
import matchSorter from "match-sorter";
import propTypes from "prop-types";

import Styles from "../styles/Combobox.module.css";
import FormStyles from "../styles/Form.module.css";
import Stack from "../styles/stack.module.css";
import { ReactComponent as Chevron } from "../icons/chevron-down.svg";

export function Combobox({
  id,
  label,
  className,
  placeholder,
  values,
  filterKeys,
  render,
  value,
  ...rest
}) {
  const [term, setTerm] = React.useState("");

  const filteredValues = useMatch(values, term, filterKeys);
  return (
    <ReachCombobox
      {...rest}
      className={cx(Stack.sm, Styles.wrapper, className)}
      // opens the list as soon as you focus input
      openOnFocus={true}
    >
      <label htmlFor={id}>{label}</label>
      <div className={Styles.inputRow}>
        <ComboboxInput
          id={id}
          autoComplete="off"
          className={cx(FormStyles.text, Styles.input)}
          onChange={(e) => setTerm(e.target.value)}
          placeholder={placeholder}
          // highlights entire input contents when you focus it
          selectOnClick={true}
          value={value}
        />
        <Chevron className={Styles.icon} width="24" height="24" />
      </div>
      {filteredValues && (
        <ComboboxPopover portal={false}>
          {filteredValues.length === 0 ? null : (
            <ComboboxList
              className={Styles.list}
              // maintains selected item if user closes/re-opens list
              persistSelection
            >
              {filteredValues.map((v) => render(v))}
            </ComboboxList>
          )}
        </ComboboxPopover>
      )}
    </ReachCombobox>
  );
}

export function Option({ className, children, ...rest }) {
  return (
    <ComboboxOption className={cx(Styles.option, className)} {...rest}>
      {children} <ComboboxOptionText />
    </ComboboxOption>
  );
}

function useMatch(values, term, keys) {
  // only re-run filter if values or term change
  return React.useMemo(() => matchSorter(values, term, { keys }), [
    values,
    term,
    keys,
  ]);
}

Combobox.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  placeholder: propTypes.string,
  filterKeys: propTypes.arrayOf(propTypes.string).isRequired,
  value: propTypes.string,
  values: propTypes.arrayOf(propTypes.object).isRequired,
  render: propTypes.func.isRequired,
};

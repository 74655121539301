import React from "react";
import cx from "classnames";
import Box from "../styles/box.module.css";
import Columns from "../styles/Columns.module.css";

function Card({ columnCount = "two", className, children, ...rest }) {
  return (
    <div {...rest} className={cx(className, Box.card)}>
      <div className={Columns[columnCount]}>{children}</div>
    </div>
  );
}

export default Card;

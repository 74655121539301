import React from "react";
import cx from "classnames";

import { Form, Field, Label, Input } from "./Form";
import Cluster from "../styles/cluster.module.css";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import Box from "../styles/box.module.css";
import {
  EditableField,
  EditableInput,
  EditButton,
  DeleteButton,
} from "./EditableInput";

export function AddPrompt({ onSubmit, slotName, index }) {
  return (
    <Form onSubmit={onSubmit}>
      <Field id={"addPrompt" + slotName + index}>
        <Label>
          How should your agent ask for{" "}
          <DetailPill index={index}>{slotName}</DetailPill>?
        </Label>
        <div className={Cluster.inputRow}>
          <Input name="addPrompt" required />
          <button
            type="submit"
            aria-label="Add prompt"
            className={Button.secondary}
          >
            Add +
          </button>
        </div>
      </Field>
    </Form>
  );
}

export function Prompts({
  prompts,
  index,
  slotName,
  deletePrompt,
  editPrompt,
}) {
  if (!prompts?.length) return null;
  return (
    <div className={Stack.md}>
      <h3>
        Prompts for <DetailPill index={index}>{slotName}</DetailPill>
      </h3>
      <ul className={Stack.md}>
        {prompts.map((prompt, i) => {
          return (
            <li key={prompt}>
              <EditableField
                label={`Prompt ${i}`}
                onEdit={(value) => editPrompt(i, value)}
              >
                <EditableInput defaultValue={prompt} />
                <EditButton />
                <DeleteButton onClick={() => deletePrompt(i)} />
              </EditableField>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export function DetailPill({ className, index, ...rest }) {
  const hue = (index * 60) % 360;
  return (
    <span
      {...rest}
      className={cx(Box.pill, className)}
      style={{
        backgroundColor: `hsl(${hue}, 30%, 88%)`,
        color: `hsl(${hue}, 20%, 25%)`,
      }}
    />
  );
}

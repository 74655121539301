import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import cx from "classnames";
import propTypes from "prop-types";

import Styles from "../styles/Confirm.module.css";
import Box from "../styles/box.module.css";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";
import Button from "../styles/button.module.css";
import Cluster from "../styles/cluster.module.css";

function Confirm({
  id,
  className,
  isOpen,
  onConfirm,
  onCancel,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  children,
  ...rest
}) {
  return (
    <DialogOverlay
      {...rest}
      isOpen={isOpen}
      className={cx(className, Styles.wrapper)}
      onDismiss={onCancel}
    >
      <DialogContent
        aria-labelledby={id}
        className={cx(Box.card, Stack.md, Styles.content)}
      >
        <p id={id} className={Text.cardTitle}>
          {children}
        </p>
        <div className={Cluster.md} style={{ "--justify": "flex-end" }}>
          <button type="button" className={Button.default} onClick={onCancel}>
            {cancelLabel}
          </button>
          <button type="button" className={Button.danger} onClick={onConfirm}>
            {confirmLabel}
          </button>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}

Confirm.propTypes = {
  id: propTypes.string.isRequired,
  className: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  cancelLabel: propTypes.string,
  confirmLabel: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Confirm;

import React from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import cx from "classnames";

import { useAuth } from "../api/auth";
import { useIntent } from "../api/intent";
import { useAgentProgress } from "../api/agent";
import Cluster from "../styles/cluster.module.css";
import Button from "../styles/button.module.css";
import HeaderStyles from "../styles/Header.module.css";
import { ReactComponent as Home } from "../icons/home.svg";
import { ReactComponent as UserCircle } from "../icons/user-circle.svg";
import { ReactComponent as ArrowUp } from "../icons/arrow-circle-up.svg";
import { ReactComponent as Checkmark } from "../icons/check.svg";

function Header({ children, ...rest }) {
  const status = useAuth();
  return (
    <header {...rest} className={HeaderStyles.header}>
      <div className={Cluster.xl} style={{ "--justify": "space-between" }}>
        <Link to="/">
          <Home className="icon-lg" />
          <span className="vh">Home</span>
        </Link>
        <Routes>
          <Route path="agents">
            {/*
              These have to be in order of reverse path specificity
              otherwise / would match everything
              https://github.com/ReactTraining/react-router/issues/7285#issuecomment-626525397
            */}
            <Route path=":agentName/:intentName/*" element={<Progress />} />
            <Route path=":agentName" element={<Progress />} />
            <Route path="/" element={<Progress />} />
          </Route>
        </Routes>
        {status === "loggedIn" && (
          <Link to="/profile" aria-label="Your profile">
            <UserCircle className="icon-lg" />
          </Link>
        )}
      </div>
    </header>
  );
}

function Progress() {
  const { agentName, intentName } = useParams();
  const progress = useAgentProgress({ agentName });

  // "/agents/legolas/greetings" matches "/agents/legolas/:intentName"
  const hasIntent =
    intentName && intentName !== "greetings" && intentName !== "new";

  const publishable = Object.values(progress).every(Boolean);
  return (
    <div className={Cluster.lg}>
      <ul className={HeaderStyles.list}>
        <ProgressItem checked={progress.hasName}>Name</ProgressItem>
        <ProgressItem checked={progress.hasGreetings}>Greetings</ProgressItem>

        {hasIntent ? (
          // if we have an intentName we're in the IntentBuilder
          // so we want to show progress for that specific intent
          <SpecificIntentProgress
            agentName={agentName}
            intentName={intentName}
          />
        ) : (
          // if we don't have a specific intentName we have to check them all
          // progress counts if there's _any_ intent with req/res
          <>
            <ProgressItem checked={progress.hasRequests}>Requests</ProgressItem>
            <ProgressItem checked={progress.hasResponses}>
              Requests
            </ProgressItem>
          </>
        )}
      </ul>
      {publishable && <Publish agentName={agentName} />}
    </div>
  );
}

function SpecificIntentProgress({ agentName, intentName }) {
  const { intent } = useIntent({
    agentName,
    intentName,
  });
  const hasRequests = !!intent?.phrases?.length;
  const hasResponses = !!intent?.emit?.length;
  return (
    <>
      <ProgressItem checked={hasRequests}>Requests</ProgressItem>
      <ProgressItem checked={hasResponses}>Responses</ProgressItem>
    </>
  );
}

function ProgressItem({ children, checked }) {
  return (
    <li className={cx(HeaderStyles.item)}>
      {children}
      <div
        className={cx(HeaderStyles.checkbox, {
          [HeaderStyles.checked]: checked,
        })}
      >
        {checked && <Checkmark className="icon-sm" />}
      </div>
    </li>
  );
}

function Publish({ agentName }) {
  return (
    <Link to={`/publish/${agentName}`} className={Button.default}>
      Publish agent <ArrowUp className="icon-sm" />
    </Link>
  );
}

export default Header;

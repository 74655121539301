import React from "react";
import {
  useParams,
  Outlet,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";
import slugify from "@sindresorhus/slugify";

import { useIntent } from "../api/intent";
import { getEntities } from "../api/agent";
import SelectedEntityContext from "./SelectedEntityContext";
import { Form, Field, Label, Input, Select } from "./Form";
import Loading from "./Loading";
import Alert from "./Alert";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Cluster from "../styles/cluster.module.css";
import Columns from "../styles/Columns.module.css";
import Text from "../styles/text.module.css";
import { ReactComponent as Pencil } from "../icons/pencil-square.svg";

function DetailsChoose({ highlightType }) {
  const { agentName, intentName } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { intent, dispatch } = useIntent({ agentName, intentName });
  const { dispatch: dispatchConfirm } = useIntent({
    agentName,
    intentName: intentName + "_confirm",
  });

  const highlight = searchParams.get("slot");
  const phraseIndex = parseInt(searchParams.get("request"), 10);
  const { status, data: entities } = useQuery(
    ["entities", agentName],
    getEntities
  );

  const [selectedEntity, setSelectedEntity] = React.useState("");
  const handleSelectEntity = (event) => {
    const entityType = event.target.value;
    setSelectedEntity(entityType);
  };

  const replaceHighlightWithSlot = (slotName) => {
    const action = {
      type:
        highlightType === "request"
          ? "replace_highlight_with_slot_name"
          : "replace_emit_highlight_with_slot_name",
      phraseIndex,
      highlight,
      slotName: slotName,
    };
    if (highlightType === "request") {
      dispatch(action);
    } else {
      const hasConfirmations = !!intent.outputContexts[0];
      const isConfirmation = highlightType === "confirm-response";
      hasConfirmations && !isConfirmation
        ? dispatchConfirm(action)
        : dispatch(action);
    }
  };

  const createNewSlot = (data) => {
    const name = slugify(data.slotName);

    replaceHighlightWithSlot(name);
    const slotToSubmit = {
      name,
      displayName: name,
      entity: data.selectDetailType,
      //set mandatory to true if the detail was recorded via the "add required detail" button (no highlighted value)
      mandatory: highlight ? false : true,
    };

    //only add a slot if the selected slot name hasnt been created already
    if (!intent.slots.some((slot) => slot.name === data.slotName)) {
      dispatch({ type: "add_slot", slot: slotToSubmit });
    }
    navigate(`/agents/${agentName}/${intentName}`);
    setSelectedEntity("");
  };
  return (
    <SelectedEntityContext.Provider value={[selectedEntity, setSelectedEntity]}>
      <div className={Columns.two}>
        <section className={Stack.lg}>
          <h2>
            {highlight ? `What is '${highlight}'?` : "Add a required detail"}
          </h2>
          <SlotPicker
            slots={intent.slots}
            onSubmit={(data) => {
              replaceHighlightWithSlot(data.existingSlotName);
              navigate(`/agents/${agentName}/${intentName}`);
            }}
            highlight={highlight}
          />
          <hr />
          {status === "error" && (
            <Alert>Something went wrong loading existing details</Alert>
          )}
          {status === "loading" && (
            <Loading size="sm">
              <span>Loading your details</span>
            </Loading>
          )}
          {status === "success" &&
            (entities?.length ? (
              <EntityPicker
                onSubmit={createNewSlot}
                entities={entities}
                onChange={handleSelectEntity}
                selectedEntity={selectedEntity}
                highlight={highlight}
              />
            ) : (
              <p className={Text.danger}>
                No details found, please create a new one.
              </p>
            ))}
        </section>
        <div>
          <Outlet />
        </div>
      </div>
    </SelectedEntityContext.Provider>
  );
}

function EntityPicker({
  entities,
  selectedEntity,
  onChange,
  highlight,
  ...rest
}) {
  const [userEntities, systemEntities] = React.useMemo(() => {
    let users = [];
    let systems = [];
    for (let entity of entities) {
      if (entity.kind === "SYS") {
        systems.push(entity);
      } else {
        users.push(entity);
      }
    }
    return [users, systems];
  }, [entities]);

  const [searchParams] = useSearchParams();

  return (
    <Form {...rest} className={Stack.md}>
      <Field id="selectDetailType">
        <Label>Or choose from existing details</Label>
        <div className={Cluster.md} style={{ "--wrap": "nowrap" }}>
          <Select
            {...rest}
            //need to set value of the select, and the value of the first option as empty strings for required validation to work
            value={selectedEntity}
            required
            onChange={onChange}
          >
            <option key="selectDetailType" value="" disabled>
              Select a detail type
            </option>
            <optgroup label="Your details">
              {userEntities.map((entity) => (
                <option key={entity.name} value={entity.name}>
                  {entity.displayName || entity.name}
                </option>
              ))}
            </optgroup>
            <optgroup label="Default details">
              {systemEntities.map((entity) => (
                <option key={entity.name} value={"@sys." + entity.name}>
                  {entity.displayName || entity.name}
                </option>
              ))}
            </optgroup>
          </Select>
          {selectedEntity &&
            !systemEntities.some(
              (entity) => "@sys." + entity.name === selectedEntity
            ) && (
              <Link
                className={Button.icon}
                //if highlight is falsy that means the user is adding new required detail that is not associated to a phrase.
                //highlight and phraseIndex are irrelevant in this case and should not be passed to the edit endpoint
                to={
                  highlight
                    ? {
                        pathname: selectedEntity,
                        search: "?" + searchParams,
                      }
                    : `${selectedEntity}`
                }
                aria-label={`Edit ${selectedEntity}`}
              >
                <Pencil className="icon-sm" />
              </Link>
            )}
        </div>
      </Field>
      <Field id="slotName">
        <Label>
          What column should this detail be added to in the spreadsheet?
        </Label>
        <Input
          required
          maxLength={27}
          pattern="[a-zA-Z][a-zA-Z0-9]{2,25}"
          errors={{
            patternMismatch:
              "Name should start with a letter followed by 2-25 letters or digits",
          }}
          validateOnBlur={true}
        />
      </Field>
      <div className={Cluster.md}>
        <Link className={Button.default} to="..">
          Cancel
        </Link>
        <button type="submit" className={Button.primary}>
          Save detail
        </button>
      </div>
    </Form>
  );
}

function SlotPicker({ slots, highlight, ...rest }) {
  // pre-select if highlight matches a slot name
  const defaultValue = slots.find((slot) => slot.name === highlight);
  return (
    <Form {...rest} className={Stack.md}>
      <Field id="existingSlotName">
        <Label>Choose from slots you already highlighted</Label>
        <div className={Cluster.inputRow}>
          <Select defaultValue={defaultValue || ""} required>
            <option key="unselectedSlotName" value="" disabled>
              Select an existing slot
            </option>
            {slots.map((slot) => (
              <option key={slot.name}>{slot.name}</option>
            ))}
          </Select>
          <button type="submit" className={Button.primary}>
            Save slot
          </button>
        </div>
      </Field>
    </Form>
  );
}

export default DetailsChoose;

import React from "react";

import { Link, useSearchParams } from "react-router-dom";
import Button from "../styles/button.module.css";
import Cluster from "../styles/cluster.module.css";
import Center from "../styles/center.module.css";

function NewDetailButton() {
  const [searchParams] = useSearchParams();
  const highlight = searchParams.get("slot");
  return (
    <section className={Cluster.md}>
      <Link
        className={`${Button.primary} ${Center.md}`}
        to={
          highlight
            ? {
                pathname: "new",
                search: "?" + searchParams,
              }
            : "new"
        }
      >
        Create new detail
      </Link>
    </section>
  );
}

export default NewDetailButton;

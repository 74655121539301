import React from "react";
import { useNavigate } from "react-router-dom";
import slugify from "@sindresorhus/slugify";
import { useMutation } from "react-query";

import { createAgent } from "../api/agent";
import { defaultIntents } from "../templates/intents";

import Card from "./Card";
import { Form, Field, Label, Info, Input } from "./Form";
import Loading from "./Loading";
import Alert from "./Alert";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";

function AgentName() {
  const navigate = useNavigate();
  const [addAgent, { status }] = useMutation(createAgent);
  return (
    <div className={Stack.lg}>
      <h1 className={Text.title}>Create a new agent</h1>
      <Card>
        <Form
          onSubmit={({ agentName }) => {
            const name = slugify(agentName, defaultIntents);
            addAgent(
              { name, displayName: agentName },
              { onSuccess: () => navigate(`../${name}/greetings/new`) }
            );
          }}
        >
          <div className={Stack.md}>
            <Field id="agentName" className={Stack.md}>
              <Label className={Text.cardTitle}>Name your new agent</Label>
              <Info>Naming your agent makes it more personal!</Info>
              <Input
                required
                // at least one non-whitespace character
                pattern=".*\S.*"
                errors={{
                  valueMissing: "Please enter a name for your agent",
                  patternMismatch: "Please enter a name for your agent",
                }}
              />
            </Field>
            <button type="submit" className={Button.primary}>
              Save name <Loading status={status} size="sm" />
            </button>
            {status === "error" && (
              <Alert dismissable={true}>
                Something went wrong saving your agent
              </Alert>
            )}
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default AgentName;

import React from "react";
import { useQuery } from "react-query";

import { getAvailablePhoneNumbers, getPhoneNumber } from "../api/phone";
import { Combobox, Option } from "./Combobox";
import Loading from "./Loading";
import Alert from "./Alert";
import { ReactComponent as Phone } from "../icons/phone.svg";

function mapNumbers(interfaces) {
  return interfaces.map((i) => ({
    ...i,
    displayName: i.interface_id.replace("+44", "0"),
  }));
}

function PhoneNumberSelect({ uniqueId, selected, onSelect }) {
  const available = useQuery("phoneNumbers", getAvailablePhoneNumbers);
  const current = useQuery(uniqueId, getPhoneNumber);
  const numbers = React.useMemo(
    () => (!available.data ? [] : mapNumbers(available.data)),
    [available.data]
  );
  console.log(
    "status, current",
    available.status,
    current.status,
    current.data
  );
  const loading = !!(
    available.status === "loading" || current.status === "loading"
  );
  const existing = !!(current.status === "success" && current?.data.length);
  return (
    <div>
      {loading && <Loading>Loading available phone numbers</Loading>}
      {!existing && available.status === "error" && (
        <Alert>Something went wrong loading available phone numbers</Alert>
      )}
      {existing && (
        <p>
          Your agent is currently available on{" "}
          {mapNumbers(current.data)[0].displayName}
        </p>
      )}
      {!loading && !existing && available.status === "success" && (
        <Combobox
          id="agentPhoneNumber"
          label="Please select a phone number for your agent"
          placeholder="Search phone numbers"
          values={numbers}
          filterKeys={["displayName"]}
          onSelect={(displayName) => {
            const num = numbers.find((n) => displayName === n.displayName);
            onSelect(num);
          }}
          render={(num) => (
            <Option key={num.interface_id} value={num.displayName}>
              <Phone className="icon-sm" />
            </Option>
          )}
        />
      )}
    </div>
  );
}

export default PhoneNumberSelect;

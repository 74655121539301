import firebase from "./firebase";

// Minimal HTTP client to handle some specific stuff we need repeatedly
// createClient allows you to make a custom client for a specific use-case
// e.g. const client = createClient({ prefixUrl: "https://my-api.com/v1/" })
// now client("dogs") will send a request to the full URL
// It will also get an auth token from Firebase unless you set `auth: false`
//
// [1] fetch doesn't error on 400/500 codes
// [2] res.json() breaks on empty bodies (e.g. 204 no content)

export function createClient({
  prefixUrl = "",
  headers = {},
  auth = true,
  json = true,
} = {}) {
  return async function client(url, options = {}) {
    options.headers = Object.assign(headers, options.headers);
    if (auth) {
      const token = await firebase.auth().currentUser.getIdToken();
      options.headers.authorization = `Bearer ${token}`;
    }
    if (json) {
      options.headers["content-type"] = "application/json";
      if (options.body) {
        options.body = JSON.stringify(options.body);
      }
    }
    return fetch(prefixUrl + url, options).then((res) => {
      if (!res.ok) throw new HttpError(res); // [1]
      if (res.status === 204) return ""; // [2]
      return res.json();
    });
  };
}

export default createClient();

class HttpError extends Error {
  constructor(response) {
    const message = response.status + " " + response.statusText;
    super(message);
    this.status = response.status;
  }
}

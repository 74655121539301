import React from "react";
import cx from "classnames";

import FormStyles from "../styles/Form.module.css";
import {
  EditableField,
  EditableInput,
  EditButton,
  DeleteButton,
} from "./EditableInput";
import { DetailPill } from "./DetailsPrompts";
import Cluster from "../styles/cluster.module.css";

function TemplatePhrase({
  phrase,
  index,
  onDelete,
  onEdit,
  onHighlight,
  slots,
}) {
  const onSelect = ({ target }) => {
    const selection = target.value.slice(
      target.selectionStart,
      target.selectionEnd
    );
    if (selection) onHighlight(selection);
  };

  const phraseParts = React.useMemo(() => extractPhrases(phrase), [phrase]);
  const slotNames = React.useMemo(() => extractSlots(phrase), [phrase]);
  return (
    <li>
      <EditableField label={`Phrase ${index}`} onEdit={onEdit}>
        {!slotNames.length ? (
          // if no slots yet just render input + edit button
          <>
            <EditableInput defaultValue={phrase} onSelect={onSelect} />
            <EditButton />
          </>
        ) : (
          // if slots we need to parse phrase string into parts
          // and render alternating readonly input + <DetailPill />
          <div
            className={cx(Cluster.xs, FormStyles.text)}
            style={{ padding: "0.25rem 0.5rem" }}
          >
            {phraseParts.map((part, partIndex) => {
              const slotName = slotNames[partIndex];
              // we need the index of this specific slot
              // so the DetailPill is the right colour
              const slotIndex = slots.findIndex((s) => s.name === slotName);
              return (
                <React.Fragment key={part + partIndex}>
                  <AutosizeInput
                    value={part}
                    onSelect={onSelect}
                    aria-label={`Highlight phrase ${index}`}
                  />
                  {slotName && (
                    <DetailPill index={slotIndex}>{slotName}</DetailPill>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
        <DeleteButton onClick={onDelete} />
      </EditableField>
    </li>
  );
}

export default TemplatePhrase;

function AutosizeInput({ value, ...rest }) {
  return (
    <div
      className={FormStyles.autosize}
      // uses data attr as pseudo-element content
      // so container is the exact size of input value
      data-value={value}
    >
      <input
        {...rest}
        className={FormStyles.ghost}
        value={value}
        readOnly={true}
        // minimum initial size
        // actual size handled by autosize parent CSS
        size={1}
      />
    </div>
  );
}

// "I want a ${fruit} at ${date}" -> ["I want a ", "at "]
function extractPhrases(str) {
  // matches any ${...}
  const partsRegexp = /\${.*?}/;
  // creates array of just non-matched substrings
  const parts = str.split(partsRegexp);
  return parts;
}

// "I want a ${fruit} at ${date}" -> ["fruit", "date"]
function extractSlots(str) {
  // captures anything inside ${...}
  const slotRegexp = /\${(.*?)}/g;
  // returns an iterator containing all matches
  const matches = str.matchAll(slotRegexp);
  // need to convert to an array e.g. [["${fruit}", "fruit"]]
  const matchesArr = [...matches];
  // slot name is the second item in each sub-array e.g. "${fruit}" -> "fruit"
  return matchesArr.map((m) => m[1]);
}

import React from "react";

import { Field, Label, Input } from "./Form";
import { AddPrompt, DetailPill } from "./DetailsPrompts";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Cluster from "../styles/cluster.module.css";
import { ReactComponent as Trash } from "../icons/trash.svg";

function DetailsList({ slots, dispatch, detailsMap }) {
  return (
    <ul className={Stack.sm}>
      {slots.map((slot, index) => (
        <Detail
          key={slot.name + index}
          slot={slot}
          index={index}
          dispatch={dispatch}
          isUsed={detailsMap.has(slot.name)}
        />
      ))}
    </ul>
  );
}

function Detail({ index, slot, dispatch, isUsed }) {
  const [open, setOpen] = React.useState(false);

  const toggleMandatory = (e) => {
    const mandatoryBoolean = e.target.checked;
    setOpen(e.target.checked);
    dispatch({
      type: "toggle_mandatory_slot",
      slotIndex: index,
      mandatoryBoolean,
    });
  };

  const onDelete = () => {
    dispatch({
      type: "delete_slot",
      index,
    });
  };
  return (
    <li className={Stack.md} style={{ marginBottom: open && "2rem" }}>
      <div className={Cluster.md}>
        <DetailPill index={index}>{slot.name}</DetailPill>
        <Field id={"toggleRequired" + slot.name + index}>
          <Input
            name={index}
            type="checkbox"
            checked={slot.mandatory}
            onChange={toggleMandatory}
          />
          <Label>Required?</Label>
        </Field>
        {!slot.mandatory && !isUsed ? (
          <button
            type="button"
            className={Button.icon}
            aria-label={`Delete ${slot.name}`}
            onClick={onDelete}
          >
            <Trash className="icon-sm" />
          </button>
        ) : null}
        {slot.mandatory && slot.prompts?.length ? (
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className={Button.default}
          >
            Add prompts {open ? "−" : "+"}
          </button>
        ) : null}
      </div>
      {(slot.mandatory && !slot.prompts?.length) || open ? (
        <AddPrompt
          index={index}
          slotName={slot.name}
          onSubmit={(data) =>
            dispatch({
              type: "add_prompt",
              slotName: slot.name,
              prompt: data.addPrompt,
            })
          }
        />
      ) : null}
    </li>
  );
}

export default DetailsList;

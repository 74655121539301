import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { useUser } from "../api/user";
import Columns from "../styles/Columns.module.css";
import Box from "../styles/box.module.css";
import Text from "../styles/text.module.css";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import HeaderStyles from "../styles/UserHeader.module.css";
import { ReactComponent as Mail } from "../icons/mail.svg";
import { ReactComponent as Phone } from "../icons/phone.svg";
import { ReactComponent as DocumentAdd } from "../icons/document-add.svg";

function UserHeader() {
  const { user } = useUser();
  if (!user) return null;
  const firstName = user?.name?.split(" ")?.[0];
  return (
    <header className={cx(Box.lg, HeaderStyles.wrapper)}>
      <div className={Columns.two}>
        <div className={Stack.md}>
          <p className={Text.xl}>
            Welcome <strong>{firstName}</strong>
          </p>
          <p>{user.company}</p>
        </div>
        <div className={Stack.lg}>
          <p className={Text.cardTitle}>{user.name}</p>
          <div className={cx(Stack.sm, Text.lg)}>
            <p>
              <Mail className="icon-sm" /> {user.email}
            </p>
            <p>
              <Phone className="icon-sm" /> {user.phone}
            </p>
          </div>
        </div>
      </div>
      <Link
        className={cx(Button.card, Button.lg, HeaderStyles.button)}
        to="agents/new"
      >
        <DocumentAdd className={HeaderStyles.icon} />
        <div className={Stack.sm}>
          <div>Create new agent</div>
          <div className={Text.subtext}>
            Create a custom telephone agent from scratch
          </div>
        </div>
      </Link>
    </header>
  );
}

export default UserHeader;

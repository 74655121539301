import React from "react";

export const NotifyContext = React.createContext();

export function useNotify() {
  const setNotification = React.useContext(NotifyContext);
  if (setNotification === undefined) {
    throw new Error(
      "useNotification must be called within a NotifyContext Provider!"
    );
  }
  return setNotification;
}

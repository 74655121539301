import React from "react";
import cx from "classnames";
import Styles from "../styles/loading.module.css";

function Loading({
  status = "loading",
  size = "md",
  className,
  as: Comp = "span",
  children,
  ...rest
}) {
  return (
    status === "loading" && (
      <div>
        {children} <Comp {...rest} className={cx(Styles[size], className)} />
      </div>
    )
  );
}

export default Loading;

import React from "react";
import cx from "classnames";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";
import Cluster from "../styles/cluster.module.css";
import Button from "../styles/button.module.css";
import FormStyles from "../styles/Form.module.css";
import { ReactComponent as Eye } from "../icons/eye.svg";
import { ReactComponent as EyeOff } from "../icons/eye-off.svg";
import { ReactComponent as Exclamation } from "../icons/exclamation.svg";
import { ReactComponent as Chevron } from "../icons/chevron-down.svg";

function Form({ onSubmit, resetOnSubmit = true, ...rest }) {
  function handleSubmit(event) {
    event.preventDefault();
    // if any inputs are invalid stop here
    const allValid = event.target.checkValidity();
    if (!allValid) return;
    // otherwise collect all the input values into an object
    const formData = new FormData(event.target);
    let data = {};
    for (let [name, value] of formData) {
      data[name] = value.trim();
    }
    // call the original onSubmit with the values object & event
    onSubmit(data, event);
    // clear inputs
    if (resetOnSubmit) event.target.reset();
  }

  return <form {...rest} noValidate onSubmit={handleSubmit} />;
}

const FieldContext = React.createContext();

function Field({ id, hideLabels = false, className, ...props }) {
  return (
    <FieldContext.Provider value={{ id, hideLabels }}>
      <div {...props} className={cx({ [Stack.sm]: !hideLabels }, className)} />
    </FieldContext.Provider>
  );
}

function Label({ className, ...props }) {
  const { id, hideLabels } = React.useContext(FieldContext);
  return (
    <label
      {...props}
      className={cx(className, FormStyles.label, { vh: hideLabels })}
      htmlFor={id}
    />
  );
}

function getValidityKey(validity) {
  for (let key in validity) {
    if (validity[key]) return key;
  }
}

function Input({
  as: Comp = "input",
  type = "text",
  className,
  errors = {},
  name,
  onInvalid,
  onChange,
  onBlur,
  validateOnBlur = false,
  ...rest
}) {
  const { id } = React.useContext(FieldContext);
  const [error, setError] = React.useState("");

  const showError = (event) => {
    // e.g. "valueMissing" or "patternMismatch"
    const validity = getValidityKey(event.target.validity);
    // get error from errors prop passed in
    // errors object should have keys that match validityState properties
    // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
    // otherwise fall back to default browser message
    const error = errors[validity] || event.target.validationMessage;
    setError(error);
    // call original onInvalid if one was passed
    onInvalid && onInvalid(event);
  };

  const clearError = (event) => {
    setError("");
    onChange && onChange(event);
  };

  const checkForError = (event) => {
    event.target.checkValidity();
    onBlur && onBlur(event);
  };

  return (
    <>
      <Comp
        {...rest}
        type={type}
        className={cx(className, FormStyles[type])}
        id={id}
        // use props.name unless it is null/undefined, then use id as fallback
        name={name ?? id}
        // always e.g. "emailInfo", only add "emailError" when there's a validation failure
        aria-describedby={cx(id + "Info", { [id + "Error"]: !!error })}
        // should be marked invalid if there's a validation failure
        aria-invalid={!!error}
        onInvalid={showError}
        onChange={clearError}
        onBlur={validateOnBlur ? checkForError : onBlur}
      />
      {error && (
        <div className={Text.danger} id={id + "error"}>
          <Exclamation className="icon" /> {error}
        </div>
      )}
    </>
  );
}

function Textarea(props) {
  return <Input {...props} as="textarea" />;
}

function Select(props) {
  return (
    <div className={FormStyles.select}>
      <Input {...props} type="none" as="select" />
      <div className={FormStyles.selectIcon} aria-hidden="true">
        <Chevron className="icon" />
      </div>
    </div>
  );
}

function Info({ className, ...props }) {
  const { id, hideLabels } = React.useContext(FieldContext);
  return (
    <div
      {...props}
      id={id + "Info"}
      className={cx(Text.subtext, className, { vh: hideLabels })}
    />
  );
}

function PasswordInput(props) {
  const [showing, setShowing] = React.useState(false);
  return (
    <div className={Cluster.md} style={{ "--wrap": "no-wrap" }}>
      <Input {...props} type={showing ? "text" : "password"} />
      <button
        type="button"
        className={Button.icon}
        onClick={() => setShowing(!showing)}
        aria-label={showing ? "Hide password" : "Show password"}
      >
        {showing ? (
          <Eye width="1.5em" height="1.5em" />
        ) : (
          <EyeOff width="1.5em" height="1.5em" />
        )}
      </button>
    </div>
  );
}

export { Form, Field, Label, Input, Textarea, Select, Info, PasswordInput };

import React from "react";
import { useVerifyEmail } from "../api/user";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import Text from "../styles/text.module.css";
import { ReactComponent as CheckCircle } from "../icons/check-circle.svg";
import { ReactComponent as ExclamationCircle } from "../icons/exclamation-circle.svg";

function VerifyEmail({ email, verified }) {
  const [sendVerification, { status: sendStatus }] = useVerifyEmail();
  return (
    <div className={Stack.md}>
      <h2 className={Text.subtext}>Email verification</h2>
      {verified ? (
        <p>
          <span className={Text.success}>
            <CheckCircle fill="var(--green-700)" className="icon" /> {email}
          </span>{" "}
          is verified.
        </p>
      ) : (
        <div className={Stack.md}>
          <p>
            <span className={Text.danger}>
              <ExclamationCircle className="icon" /> {email}
            </span>{" "}
            is not verified.
          </p>
          <button className={Button.default} onClick={() => sendVerification()}>
            {sendStatus === "success" && "Email sent. Retry?"}
            {sendStatus === "loading" && "Sending email..."}
            {sendStatus === "idle" && "Resend email"}
          </button>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;

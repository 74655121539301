import React from "react";
import { useQuery, queryCache, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";

import { publish } from "../api/phone";
import { getAgent } from "../api/agent";
import Card from "./Card";
import { Form } from "./Form";
import { getPhoneNumber } from "../api/phone";
import PhoneNumberSelect from "./PhoneNumberSelect";
import SpreadsheetSelect from "./SpreadsheetSelect";
import Loading from "./Loading";
import Alert from "./Alert";
import Button from "../styles/button.module.css";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";
import Box from "../styles/box.module.css";
import Cluster from "../styles/cluster.module.css";

function reducer(state, action) {
  // always resets error before updating state
  // sets whatever key/value pair is passed in
  // e.g. dispatch({ key: "selectedNumber", value: "020834566" })
  // or dispatch({ key: "error", value: "Please select a phone" })
  return { ...state, error: "", [action.key]: action.value };
}

function AgentPublish() {
  const [state, dispatch] = React.useReducer(reducer, {});
  const [publishAgent, { status: publishStatus }] = useMutation(publish);

  const { agentName } = useParams();

  const { status, data } = useQuery(["agents", agentName], getAgent, {
    initialData: () =>
      queryCache.getQueryData("agents")?.find((a) => a.name === agentName),
  });

  const number = useQuery(data && data.unique_id, getPhoneNumber);

  if (
    status === "success" &&
    number.status === "success" &&
    number.data.length &&
    !state.selectedNumber
  ) {
    dispatch({
      key: "selectedNumber",
      value: {
        interface_id: number.data[0].number,
        displayName: number.data[0].number.replace("+44", "0"),
      },
    });
    dispatch({
      key: "selectedSpreadsheet",
      value: {
        name: number.data[0].data.fulfillment.spreadsheetName,
        id: number.data[0].data.fulfillment.spreadsheetId,
        sheet: number.data[0].data.fulfillment.spreadsheetSheet,
      },
    });
  }
  return (
    <div className={Stack.lg}>
      <h1 className={Text.title}>Publish your agent</h1>
      <Card>
        <div>
          {publishStatus !== "success" && (
            <PublishForm
              {...state}
              status={status}
              uniqueId={data && data.unique_id}
              selectedNumber={state.selectedNumber}
              selectedSpreadsheet={state.selectedSpreadsheet}
              publishAgent={publishAgent}
              publishStatus={publishStatus}
              dispatch={dispatch}
              agentName={agentName}
            />
          )}
          {publishStatus === "success" && (
            <Success phoneNumber={state.selectedNumber} agentName={agentName} />
          )}
        </div>
      </Card>
    </div>
  );
}

function PublishForm({
  selectedNumber,
  selectedSpreadsheet,
  selectedSheet,
  status,
  error,
  publishAgent,
  publishStatus,
  uniqueId,
  dispatch,
  agentName,
}) {
  if (status === "loading") return null;
  if (status === "error")
    return <Alert>Something went wrong loading your agent</Alert>;

  return (
    <Form
      onSubmit={() => {
        const allValuesSelected = validate(
          { selectedNumber, selectedSheet, selectedSpreadsheet },
          dispatch
        );
        if (!allValuesSelected) return;
        publishAgent({
          agentName,
          unique_id: uniqueId,
          interfaceId: selectedNumber.interface_id,
          spreadsheetId: selectedSpreadsheet.id,
          spreadsheetName: selectedSpreadsheet.name,
          spreadsheetSheet: selectedSpreadsheet.sheet,
        });
      }}
    >
      <div className={Stack.lg}>
        <PhoneNumberSelect
          uniqueId={uniqueId}
          selectedNumber={selectedNumber}
          onSelect={(value) => dispatch({ key: "selectedNumber", value })}
        />
        <SpreadsheetSelect
          onSelectSpreadsheet={(value) =>
            dispatch({ key: "selectedSpreadsheet", value })
          }
          selectedSpreadsheet={selectedSpreadsheet}
        />
        <button type="submit" className={Button.primary}>
          Publish <Loading status={publishStatus} size="sm" />
        </button>
        {error && <Alert dismissable={true}>{error}</Alert>}
        {publishStatus === "error" && (
          <Alert dismissable={true}>
            Something went wrong publishing your agent
          </Alert>
        )}
      </div>
    </Form>
  );
}

function validate(
  { selectedNumber, selectedSpreadsheet, selectedSheet },
  dispatch
) {
  if (!selectedNumber) {
    dispatch({
      key: "error",
      value: "Please select a phone number from the list",
    });
  } else if (!selectedSpreadsheet) {
    dispatch({
      key: "error",
      value: "Please select a spreadsheet from the list",
    });
  } else if (!selectedSpreadsheet.sheet || !selectedSpreadsheet.sheet.title) {
    dispatch({
      key: "error",
      value: "Please select a spreadsheet tab from the list",
    });
  } else {
    return true;
  }
}

function Success({ phoneNumber, agentName }) {
  return (
    <div className={Stack.xl}>
      <div className={Stack.md}>
        <h2>Success! Your agent is live</h2>
        <p>
          You and your customers can now talk to your agent at this phone
          number:
        </p>
        <div
          className={cx(Box.md, Box.round)}
          style={{ "--bg": "var(--success-bg)", maxWidth: "max-content" }}
        >
          <p className={cx(Text.xl, Text.success)}>{phoneNumber.displayName}</p>
        </div>
      </div>
      <div className={Cluster.md}>
        <Link to="/" className={Button.default}>
          Go to dashboard
        </Link>
        <Link to={`/test/${agentName}`} className={Button.primary}>
          Test your agent
        </Link>
      </div>
    </div>
  );
}

export default AgentPublish;

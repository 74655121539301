import React from "react";

import Card from "./Card";
import { Form, Field, Label, Input } from "./Form";
import { useParams } from "react-router-dom";
import { useIntent } from "../api/intent";
import Cluster from "../styles/cluster.module.css";
import Button from "../styles/button.module.css";
import Text from "../styles/text.module.css";

function EndConversation({ dispatch, intent }) {
  const { agentName, intentName } = useParams();
  const {
    status,
    intent: confirmIntent,
    dispatch: dispatchToConfirmationIntent,
  } = useIntent({
    agentName,
    intentName: intentName + "_confirm",
  });

  const hasConfirmations = intent.outputContexts[0];
  const isEndOfConversation = hasConfirmations
    ? confirmIntent.isTerminal
    : intent.isTerminal;

  return (
    <Card>
      {status === "success" && (
        <AddGoodbyeOrFurtherPrompt
          title={
            isEndOfConversation
              ? "Add a goodbye message"
              : "Add a further prompt"
          }
          responses={hasConfirmations ? confirmIntent.emit[0] : intent.emit[0]}
          id={"goodbyeOrFurtherPrompt"}
          onSubmit={(data) => {
            hasConfirmations
              ? dispatchToConfirmationIntent({
                  type: "add_goodbye_or_further_prompt",
                  goodbyeOrFurtherPrompt: data.goodbyeOrFurtherPrompt,
                })
              : dispatch({
                  type: "add_goodbye_or_further_prompt",
                  goodbyeOrFurtherPrompt: data.goodbyeOrFurtherPrompt,
                });
          }}
          placeholder={
            isEndOfConversation
              ? "e.g. Thanks for calling!"
              : "e.g. Is there anything else I can help with?"
          }
          defaultValue={
            hasConfirmations ? confirmIntent.emit[1]?.[0] : intent.emit[1]?.[0]
          }
        />
      )}
    </Card>
  );
}

function AddGoodbyeOrFurtherPrompt({
  title,
  id,
  placeholder,
  responses,
  defaultValue,
  ...rest
}) {
  return (
    <Form {...rest}>
      <Field id={id}>
        <Label className={Text.cardTitle}>{title}</Label>
        <div className={Cluster.inputRow}>
          <Input
            required
            placeholder={placeholder}
            pattern=".*\S.*"
            errors={{
              valueMissing: "Please enter a value",
              patternMismatch:
                "Please enter atleast one non whitespace character",
            }}
            defaultValue={defaultValue}
          />
          <button type="submit" className={Button.secondary}>
            Save
          </button>
        </div>
      </Field>
    </Form>
  );
}

export default EndConversation;

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import slugify from "@sindresorhus/slugify";

import { useCreateIntent } from "../api/intent";
import { useNotify } from "./NotifyContext";
import Card from "./Card";
import IntentName from "./IntentName";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";

function IntentNew() {
  const { agentName } = useParams();
  const setNotification = useNotify();
  const [createIntent] = useCreateIntent();
  const navigate = useNavigate();

  return (
    <section className={Stack.lg} style={{ minWidth: "45ch" }}>
      <h1 className={Text.title}>Create a request</h1>
      <Card>
        <IntentName
          onSubmit={(data) => {
            const name = slugify(data.intentName);
            const displayName = data.intentName;
            createIntent(
              { agentName, name, displayName },
              {
                onSuccess: (response) => {
                  setNotification(
                    `Request ${response.displayName} created successfully!`
                  );
                  navigate(`../${name}`);
                },
              }
            );
          }}
          resetOnSubmit={false}
        />
      </Card>
    </section>
  );
}

export default IntentNew;

import { useQuery, useMutation, queryCache } from "react-query";
import firebase, { googleAuth } from "./firebase";
import { createClient } from "./client";
import { useNavigate } from "react-router-dom";

const backend = process.env.REACT_APP_BACKEND_SERVER;

const client = createClient({ prefixUrl: backend });

function signUp(email, password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

function logIn(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

async function signInWithGoogle() {
  const googleUser = await (await googleAuth).signIn();
  const cred = firebase.auth.GoogleAuthProvider.credential(
    null,
    googleUser.getAuthResponse().access_token
  );
  const offlineToken = await googleUser.grantOfflineAccess();
  return firebase
    .auth()
    .signInWithCredential(cred)
    .then((result) => {
      window.localStorage.setItem("googleToken", result.credential.accessToken);
      client("/api/usertoken", {
        method: "POST",
        body: offlineToken,
      });
      return result;
    });
}

function logOut() {
  return firebase.auth().signOut();
}

function sendVerificationEmail() {
  return firebase.auth().currentUser.sendEmailVerification();
}

function useVerifyEmail() {
  return useMutation(sendVerificationEmail);
}

function getUser() {
  return client("/api/user");
}

function useUser() {
  const { data, ...rest } = useQuery("user", getUser, {
    staleTime: Infinity,
  });
  // we don't store these things in the DB, so have to grab from FB
  const { email, emailVerified, photoUrl } = firebase.auth().currentUser;
  return { user: { ...data, email, emailVerified, photoUrl }, ...rest };
}

function createUser(navigate) {
  return (user) => {
    return signInWithGoogle().then(() => {
      navigate("/");
      return client("/api/user", {
        method: "POST",
        body: user,
      });
    });
  };
}

function useCreateUser() {
  const navigate = useNavigate();
  return useMutation(createUser(navigate), {
    onSuccess: (response, user) => {
      queryCache.setQueryData("user", user);
    },
  });
}

function useLogin() {
  const navigate = useNavigate();
  return useMutation(signInWithGoogle, {
    onSuccess: () => {
      navigate("/");
    },
  });
}

function updateUser(user) {
  return client("/api/user", {
    method: "PUT",
    body: user,
  });
}

function useUpdateUser() {
  return useMutation(updateUser, {
    onMutate: (user) => {
      queryCache.cancelQueries("user");
      const oldUser = queryCache.getQueryData("user");
      queryCache.setQueryData("user", { ...oldUser, ...user });
      return () => queryCache.setQueryData("user", oldUser);
    },
    onError: (err, data, rollback) => rollback(),
  });
}

function deleteUser() {
  return firebase.auth().currentUser.delete();
}

export {
  signUp,
  logIn,
  logOut,
  useCreateUser,
  useLogin,
  useVerifyEmail,
  useUpdateUser,
  getUser,
  signInWithGoogle,
  useUser,
  deleteUser,
};

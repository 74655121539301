import React from "react";
import { useQuery } from "react-query";
import { getAllSpreadsheets, getSpreadsheet } from "../api/spreadsheet";
import { Combobox, Option } from "./Combobox";
import Loading from "./Loading";
import Alert from "./Alert";
import Stack from "../styles/stack.module.css";
import Text from "../styles/text.module.css";
import Button from "../styles/button.module.css";
import { ReactComponent as GoogleSheetsIcon } from "../icons/google-sheets.svg";

function SpreadsheetSelect({
  onSelectSpreadsheet,
  selectedSpreadsheet,
  onSelectSheet,
}) {
  const { status, data, refetch } = useQuery(
    "spreadsheets",
    getAllSpreadsheets,
    { enabled: false }
  );

  return (
    <div className={Stack.md}>
      {status === "idle" && (
        <button onClick={refetch} type="button" className={Button.default}>
          {selectedSpreadsheet && selectedSpreadsheet.name
            ? `Linked to ${selectedSpreadsheet.name}`
            : "Link a spreadsheet"}
          {"   "}
          <GoogleSheetsIcon className="icon-sm" fill="var(--success-text)" />
        </button>
      )}
      {status === "loading" && <Spinner>Select a spreadsheet</Spinner>}
      {status === "error" && (
        <Alert>Something went wrong loading your spreadsheets</Alert>
      )}
      {status === "success" && (
        <Combobox
          id="sheet-select"
          label="Results in Spreadsheet"
          placeholder="Search sheets"
          values={data.files}
          filterKeys={["name"]}
          onSelect={(sheetName) => {
            const sheet = data.files.find((v) => v.name === sheetName);
            onSelectSpreadsheet(sheet);
          }}
          render={(spreadsheet) => (
            <Option key={spreadsheet.id} value={spreadsheet.name}>
              <GoogleSheetsIcon
                fill="var(--success-text)"
                className="icon-sm"
              />
            </Option>
          )}
        />
      )}
      {!selectedSpreadsheet ? null : (
        <SheetSelect
          selectedSpreadsheet={selectedSpreadsheet}
          onSelectSpreadsheet={onSelectSpreadsheet}
        />
      )}
    </div>
  );
}

function SheetSelect({ selectedSpreadsheet, onSelectSpreadsheet }) {
  let sheetId = selectedSpreadsheet.id;
  const { status, data } = useQuery(["spreadsheets", sheetId], getSpreadsheet);

  if (status === "loading") return <Spinner>Select a tab</Spinner>;
  if (status === "error")
    return <Alert>Something went wrong loading your spreadsheet tabs</Alert>;
  console.log("sheetRender", selectedSpreadsheet);
  return (
    <Combobox
      id="spreadsheet-select"
      label="Tab"
      placeholder="Search tabs"
      value={selectedSpreadsheet.sheet && selectedSpreadsheet.sheet.title}
      values={data.sheets}
      filterKeys={["properties.title"]}
      onSelect={(title) => {
        const sheet = data.sheets.find((v) => v.properties.title === title);
        onSelectSpreadsheet({
          ...selectedSpreadsheet,
          sheet: sheet.properties,
        });
      }}
      render={(sheet) => (
        <Option key={sheet.properties.sheetId} value={sheet.properties.title}>
          <GoogleSheetsIcon fill="var(--success-text)" className="icon-sm" />
        </Option>
      )}
    />
  );
}

function Spinner({ children, ...rest }) {
  return (
    <div {...rest} className={Stack.sm}>
      <span className={Text.default} style={{ "--weight": "bold" }}>
        {children}
      </span>
      <Loading />
    </div>
  );
}

export default SpreadsheetSelect;

import React from "react";

import { Form, Field, Label, Info, Input } from "./Form";
import TemplatePhrase from "./TemplatePhrase";
import Cluster from "../styles/cluster.module.css";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import { useNavigate } from "react-router-dom";

export function AddPhrase(props) {
  return (
    <Form {...props}>
      <Field id="addPhrase">
        <Label>Type a request to train your agent</Label>
        <Info>Add as many variations as you like</Info>
        <div className={Cluster.inputRow}>
          <Input required />
          <button type="submit" className={Button.secondary}>
            Add +
          </button>
        </div>
      </Field>
    </Form>
  );
}

export function Phrases({ phrases, slots, deletePhrase, editPhrase }) {
  const navigate = useNavigate();

  if (!phrases?.length) return null;
  return (
    <div className={Stack.md}>
      <h3>Your requests</h3>
      <p>Highlight the details you want your agent to extract.</p>

      <ul className={Stack.md}>
        {phrases.map((phrase, i) => (
          <TemplatePhrase
            onDelete={() => deletePhrase(i)}
            onEdit={(value) => editPhrase(i, value)}
            onHighlight={(selection) => {
              navigate({
                pathname: "details",
                search:
                  "?" +
                  new URLSearchParams({
                    slot: selection,
                    request: i,
                  }),
              });
            }}
            phrase={phrase}
            slots={slots}
            index={i}
            key={phrase + i}
          />
        ))}
      </ul>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import App from "./App";
import { AuthProvider } from "./api/auth";
import "./styles/global.css";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ReactQueryDevtools />
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import cx from "classnames";

import FormStyles from "../styles/Form.module.css";
import Button from "../styles/button.module.css";
import { ReactComponent as Pencil } from "../icons/pencil-square.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";

const EditableFieldContext = React.createContext();

export function EditableField({
  label,
  children,
  editable = true,
  onEdit,
  ...rest
}) {
  const [editing, setEditing] = React.useState(false);
  // focus the input when the edit button is clicked
  const inputRef = React.useRef();
  React.useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEditing = React.useCallback(() => {
    if (editable) {
      if (editing) {
        onEdit(inputRef.current.value);
      }
      setEditing((editing) => {
        return !editing;
      });
    }
  }, [editable, editing, onEdit]);

  const contextValue = React.useMemo(() => {
    return { label, editing, toggleEditing, editable, inputRef };
  }, [label, editing, toggleEditing, editable]);

  return (
    <EditableFieldContext.Provider value={contextValue}>
      <div style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
        {children}
      </div>
    </EditableFieldContext.Provider>
  );
}

export function EditableInput({ onSelect, className, ...rest }) {
  const { label, inputRef, editing, toggleEditing } = React.useContext(
    EditableFieldContext
  );
  return (
    <input
      {...rest}
      className={cx(className, FormStyles.text)}
      aria-label={label}
      ref={inputRef}
      readOnly={!editing}
      // bit hacky but can't think of a better way
      // since wherever this is rendered won't know if editing or not
      onSelect={!editing ? onSelect : undefined}
      onKeyDown={(e) => editing && e.key === "Enter" && toggleEditing()}
    />
  );
}

export function EditButton({ className, ...rest }) {
  const { label, editing, toggleEditing, editable } = React.useContext(
    EditableFieldContext
  );
  return (
    <button
      type="button"
      className={cx(Button.icon, className)}
      onClick={toggleEditing}
      aria-label={editing ? `Save ${label}` : `Edit ${label}}`}
      disabled={!editable}
    >
      {editing ? <Check className="icon-sm" /> : <Pencil className="icon-sm" />}
    </button>
  );
}

export function DeleteButton({ className, ...rest }) {
  const { label } = React.useContext(EditableFieldContext);
  return (
    <button
      type="button"
      className={cx(Button.icon, className)}
      aria-label={`Delete ${label}`}
      {...rest}
    >
      <Trash className="icon-sm" />
    </button>
  );
}

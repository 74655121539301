import React from "react";

import DetailBuilder from "./DetailBuilder";
import SelectedEntityContext from "./SelectedEntityContext";
import { createEntity } from "../api/agent";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, queryCache } from "react-query";
import Stack from "../styles/stack.module.css";
import { invalidateAgentsIfFirstChange } from "../api/intent";

function DetailNew() {
  const { agentName } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const highlight = searchParams.get("slot");
  const [, setSelectedEntity] = React.useContext(SelectedEntityContext);

  const navigateBack = () => {
    if (highlight) {
      navigate({
        pathname: "..",
        search: "?" + searchParams,
      });
    } else {
      navigate("..");
    }
  };

  const [postDetail, { status: submitStatus }] = useMutation(createEntity, {
    onSuccess: (entity) => {
      invalidateAgentsIfFirstChange(agentName);
      queryCache.invalidateQueries("entities").then(() => {
        setSelectedEntity(entity.type);
        navigateBack();
      });
    },
  });

  return (
    <section className={Stack.md}>
      <h2>Create a new detail</h2>
      <DetailBuilder
        submitStatus={submitStatus}
        onSubmit={(entity) => postDetail({ agentName, entity })}
      />
    </section>
  );
}

export default DetailNew;

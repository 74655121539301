import React from "react";
import { useUser, logOut, deleteUser } from "../api/user";
import Loading from "./Loading";
import Alert from "./Alert";
import UpdateUserDetails from "./UpdateUserDetails";
import VerifyEmail from "./VerifyEmail";
import Card from "./Card";
import Confirm from "./Confirm";
import Stack from "../styles/stack.module.css";
import Center from "../styles/center.module.css";
import Button from "../styles/button.module.css";
import Text from "../styles/text.module.css";
import { ReactComponent as Logout } from "../icons/logout.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";

function Profile() {
  const { status, user } = useUser();
  return (
    <section className={Center.md}>
      <div className={Stack.lg}>
        <h1 className={Text.title}>Your profile</h1>
        <Card columnCount={1}>
          {status === "loading" && <Loading>Loading user details</Loading>}
          {status === "loading" && (
            <Alert>Something went wrong loading your details</Alert>
          )}
          {status === "success" && (
            <div className={Stack.lg}>
              <UpdateUserDetails
                name={user.name}
                phone={user.phone}
                company={user.company}
              />
              <VerifyEmail email={user.email} verified={user.emailVerified} />
              <button className={Button.default} onClick={logOut}>
                Log out <Logout className="icon-sm" />
              </button>
              <DeleteAccount />
            </div>
          )}
        </Card>
      </div>
    </section>
  );
}

function DeleteAccount() {
  const [showConfirm, setShowConfirm] = React.useState(false);
  return (
    <>
      <button className={Button.danger} onClick={() => setShowConfirm(true)}>
        Delete your account <Trash className="icon-sm" />
      </button>
      <Confirm
        id="deleteAccount"
        isOpen={showConfirm}
        onCancel={() => setShowConfirm(false)}
        confirmLabel="Delete"
        onConfirm={() => {
          setShowConfirm(false);
          deleteUser();
        }}
      >
        Are you sure you want to delete your account?
      </Confirm>
    </>
  );
}

export default Profile;

import * as firebase from "firebase/app";
import "firebase/auth";
import { gapi, loadAuth2 } from "gapi-script";

const scopes = [
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/drive.readonly",
];

let googleAuth = loadAuth2(
  gapi,
  process.env.REACT_APP_GOOGLE_CLIENT_ID,
  scopes.join(" ")
);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
  authDomain: "smbconnect.firebaseapp.com",
  projectId: "smbconnect",
  appId: "1:1010234949563:web:9ee3394cb5529765f04e9e",
};

firebase.initializeApp(firebaseConfig);

const googleProvider = new firebase.auth.GoogleAuthProvider();
// add Google Sheets permissions
scopes.forEach((scope) => googleProvider.addScope(scope));

export { googleProvider, googleAuth };

export default firebase;

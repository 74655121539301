import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, queryCache } from "react-query";
import cx from "classnames";
import Stack from "../styles/stack.module.css";
import Button from "../styles/button.module.css";
import Cluster from "../styles/cluster.module.css";
import Box from "../styles/box.module.css";
import Text from "../styles/text.module.css";
import MessageBox from "../styles/MessageBox.module.css";

import { Form, Field, Label, Input } from "./Form";
import Loading from "./Loading";
import { ReactComponent as ExclamationCircle } from "../icons/exclamation-circle.svg";

import {
  getAgent,
  initialiseTestInterface,
  sendTestMessage,
} from "../api/agent";

function conversationReducer(conversation, action) {
  switch (action.type) {
    case "add_user_message": {
      return [
        ...conversation,
        {
          from: "user",
          text: action.text,
        },
      ];
    }
    case "add_agent_message": {
      return [
        ...conversation,
        {
          from: "agent",
          text: action.text,
        },
      ];
    }
    default:
      return conversation;
  }
}

const initialiseMessage = {
  contexts: ["welcome"],
  text: "",
};

function AgentTest() {
  const { agentName } = useParams();

  const { status: fetchAgentStatus, data: agentData } = useQuery(
    ["agents", agentName],
    getAgent,
    {
      initialData: () =>
        queryCache.getQueryData("agents")?.find((a) => a.name === agentName),
    }
  );

  const { status: initialiseStatus, data: initialiseResponse } = useQuery(
    ["getSessionId", agentName, "dialogflow", initialiseMessage],
    initialiseTestInterface,
    {
      refetchOnWindowFocus: false,
      onSuccess: (messageResponse) => {
        //update context for next message to send
        setPreviousContexts(messageResponse.contexts);
        //add agent's response text to state, to display on screen
        dispatch({
          type: "add_agent_message",
          text: messageResponse.text,
        });
      },
    }
  );

  const [previousContexts, setPreviousContexts] = React.useState([]);

  //a record of the entire conversation, will have the format [{from:"agent",text:"hello"},{from:"user",text:"i would like bananas"}]
  const [conversation, dispatch] = React.useReducer(conversationReducer, []);

  const [postTestMessage] = useMutation(sendTestMessage);

  function handleSendMessage(data) {
    //construct a request body using sessionId, contexts from previous response, and text entered by user
    const messageToSend = {
      session: initialiseResponse.session,
      contexts: previousContexts,
      text: data.testMessage,
    };
    //add to conversation state to display message on screen
    dispatch({ type: "add_user_message", text: data.testMessage });
    //send to api
    postTestMessage(
      {
        agentName,
        serviceName: "dialogflow",
        message: messageToSend,
      },
      {
        onSuccess: (messageResponse) => {
          //update context for next message to send
          setPreviousContexts(messageResponse.contexts);
          //add agent's response text to state, to display on screen
          dispatch({
            type: "add_agent_message",
            text: messageResponse.text,
          });
        },
      }
    );
  }

  return (
    <div className={Stack.lg}>
      <h1 className={Text.title}>
        Test Agent {fetchAgentStatus === "success" && agentData.displayName}
      </h1>
      <section className={cx(Box.card, Stack.md)}>
        {initialiseStatus === "loading" && (
          <Loading>Loading Test Interface</Loading>
        )}
        {initialiseStatus === "error" && (
          <p className={Text.danger}>
            <ExclamationCircle className="icon" /> Something went wrong while
            loading the test interface
          </p>
        )}
        {initialiseStatus === "success" && (
          <div className={Stack.lg}>
            <div className={cx(MessageBox.container, Stack.sm)}>
              {conversation.map((message, id) => (
                <Message key={id} from={message.from} text={message.text} />
              ))}
            </div>
            <Form
              onSubmit={handleSendMessage}
              style={{ "--bg": "var(--blue-200)", width: "100%" }}
            >
              <Field id="testMessage">
                <Label>Type a message to send to your agent</Label>
                <div className={Cluster.inputRow}>
                  <Input
                    required
                    pattern=".*\S.*"
                    errors={{
                      valueMissing: "Please enter a message",
                      patternMismatch: "Please enter a message",
                    }}
                  ></Input>
                  <button className={Button.success}>Send</button>
                </div>
              </Field>
            </Form>
          </div>
        )}
      </section>
    </div>
  );
}

function Message({ from, text }) {
  return <p className={cx(MessageBox.message, MessageBox[from])}>{text}</p>;
}
export default AgentTest;
